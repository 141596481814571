import React from "react";
// import PropTypes from "prop-types";
// import ScribeSearch from "@ai-antd/page/ScribeSearch";
import {Menu, Layout} from "antd";
import {AppContext, WindowContext} from "@web/hooks/context";
// import Logo from "./Logo";
// import AccountMenu from "./AccountMenu";
// import VoiceCallManager from "./VoiceCallManager";
// import searchActions from "@web/redux/search/actions";
import {useDispatch, useSelector} from "react-redux";
// import IconTip from "./IconTip";
// import {EmailIconOutlined, HomeOutlined} from "@web/assets/icons";
// import HotlineIdentity from "./HotlineIdentity";
// import EmailAction from "./EmailAction";
import {useLocation} from "wouter";
// import appActions from "@web/redux/app/actions";
// import contentActions from "@web/redux/content/actions";
// import aiActions from "@web/redux/ai/actions";
import PropTypes from "prop-types";
// import Logo from "./Logo";
import voiceActions from "@web/redux/voice/actions";
import NavigationLabel from "./NavigationLabel";

DashboardHeader.propTypes = {
  right: PropTypes.node,
  isMobile: PropTypes.bool,
};

function DashboardHeader(props) {
  const dispatch = useDispatch();
  const [, user, claims] = React.useContext(AppContext);
  const registering = useSelector((state) => state.Voice.registering);
  const registered = useSelector((state) => state.Voice.registered);
  // const callError = useSelector((state) => state.Voice.callError);
  // const [open, setOpen] = React.useState(false);
  const [location, setLocation] = useLocation();
  const [current, setCurrent] = React.useState("/chat");

  React.useEffect(() => {
    if (registered || registering) return;
    dispatch(voiceActions.register());
  }, []);

  const items = [];

  !user &&
  items.push({
    label: <img width={92} src="/icons/line-on-dark.png"/>,
    key: '/',
    children: [
      {
        type: 'group',
        label: "",
        children: [
          {
            label: 'Messenger',
            key: `/${claims.data?._h}`,
          },
          {
            label: 'Learn',
            key: '/start',
          },
        ],
      },
    ],
  });

  user &&
  !user.isAnonymous &&
  items.push({
    label: <NavigationLabel text="Chat" active={location.startsWith("/pro")} />,
    key: '/pro',
  });

  user &&
  !user.isAnonymous && items.push({
    label: <NavigationLabel text="Tasks" active={location.startsWith("/line")} />,
    key: "/line",
    disabled: false,
  });

  const onClick = ({key}) => {
    window.location.href = key;
  }

  if (location === "/pro" || location.charAt(1) === "+") {
    return null;
  }

  return <Layout.Header
    className="transparent-bg"
    style={{
      position: "fixed",
      top: 0, left: 0, right: 0,
      zIndex: 100,
      borderBottom: location === "/pro" ? "1px solid rgba(255, 255, 255, 0.075)" : "light",
      ...props.isMobile ? {paddingLeft: 10, paddingRight: 10} : {},
    }}
  >
    <div className={`space space-between ${location === "/pro" ? "sky-blue-bg" : ""}`}>
      <div style={{marginRight: 20}}>
        <a href="/"><img width={72} src="/icons/line-on-dark.png" /></a>
      </div>
      <Menu
        className="transparent-bg"
        // theme={location === "/pro" ? "dark" : "light"}
        theme={"dark"}
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        style={{width: "100%",}}
      />
      {props.right}
    </div>
  </Layout.Header>;
}

export default DashboardHeader;
