import React from 'react';
import PropTypes from 'prop-types';
import {Button, Comment, Tag, Tooltip, Typography} from "antd";
import {ActivityIcon, AttachmentIcon, ForwardIcon, ReplyIcon} from "@web/assets/icons";
import {AppContext} from "@web/hooks/context";
import TimeAgo from "@web/ui/components/TimeAgo";
import UserAvatar from "./UserAvatar";
import MarkdownTimeline from "./MarkdownTimeline";
import {useSelector} from "react-redux";
import Day from "./Day";
import DetailItemList from "./DetailItemList";
import contentActions from "@web/redux/content/actions";
import EmailAction from "./EmailAction";

TimelineEmail.propTypes = {
  path: PropTypes.string.isRequired,
  date: PropTypes.number,
  hideSubject: PropTypes.bool,
  showAttachmentCount: PropTypes.bool,
  icon: PropTypes.element,
};

function TimelineEmail(props) {
  const [, user] = React.useContext(AppContext);

  /** @type {models.EmailMessage.Data} */
  const email =
    useSelector((state) => {
      return state.Content.data?.[props.path];
    });

  const itemsList = useSelector((state) => {
    return state.Content?.activity?.[props.path]?.itemsList;
  });

  // console.log("TimelineEmail:email:", email);

  if (!email) {
    return null
  }

  return <div className="space space-col">
    {!props.hideSubject &&
      <label className="font-xl muted">
        {email.subject}
      </label>
    }
    <Comment
      avatar={
        // <UserAvatar
        //   icon={DomainIcon}
        //   userId={props.path.split("/").pop()}
        //   name={contact.name}
        //   displayName={displayName}
        //   src={contact.photoURL}
        // />}
      <UserAvatar
        displayName={email.from?.name || email.from?.email}
        size={"large"}
        icon={props.icon}
      />}
      author={
      <label
        className="ant-comment-content-author-name"
      >{email.from?.email === user.email ?
        user.displayName :
        email.from?.name ||
        email.from?.email
        ?.split("@")?.[0]}
      </label>}
      datetime={<Day ts={email.date} />}
      content={
        <MarkdownTimeline
          markdown={email.markdown || email.text || email.snippet}
          components={{
            p: (props) =>
              <Typography.Paragraph
                ellipsis={{rows: 8, expandable: true}}
                className="render-line-breaks mb-0">
                {props.children}
            </Typography.Paragraph>
          }}
        />
      }
      actions={[
        <EmailAction
          key="reply"
          action="reply"
          emailPath={props.path}
        >
          <Button
            shape="round"
            className="icon-btn"
          >
            <ReplyIcon size={24} className="green-icon"/>&nbsp;
            <h6 className="green-color">Reply</h6>
          </Button>
        </EmailAction>,
        <EmailAction
          key="forward"
          action="forward"
          emailPath={props.path}
        >
          <Button
            shape="round"
            className="icon-btn"
          >
            <ForwardIcon size={24} className="green-icon"/>&nbsp;
            <h6 className="green-color">Forward</h6>
          </Button>
        </EmailAction>,
          props.showAttachmentCount &&
        Object.keys(email.attachments || {}).length ?
          <Button shape="round" className="icon-btn">
            <AttachmentIcon className="muted-icon"/>
            <label className="muted">
              {Object.keys(email.attachments).length}
            </label>
          </Button> :
          null,
          // <Button
          //   shape="round"
          //   className="icon-btn muted"
          //   onClick={() => dispatch(contentActions.loadContext(props.path))}
          // >
          //   <ActivityIcon className="muted-icon"/>
          //   {itemsList?.map(([path]) => <Tag>{path.split("/")[2]}</Tag>)}
          // </Button>
        ]
      }
    />
    </div>
}

export default TimelineEmail;
