import React from "react";
import PropTypes from "prop-types";
import {Button, Table} from "antd";
import {KanbanIcon, PlusIcon} from "@web/assets/icons";
import MarkdownTimeline from "./MarkdownTimeline";

KanbanTask.propTypes = {
  task: PropTypes.object,
  width: PropTypes.number,
  bordered: PropTypes.bool,
  steps: PropTypes.array,
};

function KanbanTask(props) {
  return <Table
    style={{width: props.width || 800, flex: 'none'}}
    title={() => <KanbanIcon/>}
    bordered={!!props.bordered}
    loading={false}
    dataSource={props.steps || []}
    columns={
      props.columns.map((field, index) => ({
        title: field.title,
        dataIndex: field.dataIndex,
        key: field.key,
        width: field.width ?? 200,
        fixed: index === 0 ? "left" : undefined,
        render: (text) => <label className="font-lg line-clamp-1x">{text}</label>,
      })) || []
    }
    // rowSelection={{}}
    expandable={{
      expandedRowRender: (record) => (
        <MarkdownTimeline
          markdown={record[record.expandField]}
          // action={[<Button ghost size="small"><PlusIcon size={18} className="green-icon" /></Button>]}
        />
      ),
    }}
    pagination={false}
    scroll={{
      x: 600,
      y: 660,
    }}
  />
}

export default KanbanTask;
