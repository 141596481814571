import React, {Fragment} from 'react'
import ChatPrompt from './ChatPrompt'
import ChatResponse from './ChatResponse'
import ChatResponseStream from './ChatResponseStream'
import PropTypes from "prop-types";
import useChatStreamListener from "@web/hooks/useChatStreamListener";

ChatItem.propTypes = {
  path: PropTypes.string.isRequired,
  chat: PropTypes.object.isRequired,
  // stream: PropTypes.object,
  chatIndex: PropTypes.number.isRequired,
  primary: PropTypes.bool,
  hidePrompt: PropTypes.bool,
};

export default function ChatItem(props) {
  const [collapsed, setCollapsed] = React.useState(false);
  // const [chatStream, chatPath,, resetStream] = useChatStreamListener(props.agentId, props.collectionPath, props.path);
  const toggleCollapsed = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  // console.log("ChatItem", props.path, chatStream);

  // if (!props.path) {
  //   console.log("Path is now required in AI chat", {path: props.path})
  // }

  return <Fragment>
    {/*{props.primary && <h6>Feedback</h6>}*/}
    {!props.hidePrompt &&
      <ChatPrompt
        path={props.path}
        chat={props.chat}
        onClick={toggleCollapsed}
        collapsed={collapsed}
      />}
    {!collapsed && !props.stream &&
      <ChatResponse
        chat={props.chat}
        path={props.path}
        chatIndex={props.chatIndex}
      />}
    {!collapsed && props.stream &&
      <ChatResponseStream
        // chat={chatStream.chat}
        path={chatPath}
        stream={props.stream}
        // chatIndex={props.chatIndex}
      />}
    {/*<AssistantSearchResults chatId={chatId} chat={chat} like={chat.like} collectionPath={collectionPath} />*/}
  </Fragment>
}
