import React from 'react';
import {List} from "antd";
import PropTypes from 'prop-types'
import AppIntegrationListItem from "./AppIntegrationListItem";
import {Link} from "wouter";

AppIntegrationsList.propTypes = {
  pairs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  header: PropTypes.node,
  pageSize: PropTypes.number,
  iconStyle: PropTypes.object,
  className: PropTypes.string,
  pagination: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
};

function AppIntegrationsList({pairs = [], header, pageSize = 10, iconStyle = null, className, tags = [], pagination = {pageSize, showSizeChanger: false}}) {
  const renderItem = ([integrationId, integration]) => {
    return <AppIntegrationListItem
      key={integrationId}
      integration={integration}
      integrationId={integrationId}
      iconStyle={iconStyle}
    />
  }
  const [displayName = ''] = tags;
  return <List
    pagination={(pairs?.length && pairs?.length > pageSize) ? pagination : null}
    className={`scribe-widget app-integrations-list ${className || ''}`}
    locale={{emptyText: displayName ? <Link className="font-lg" to="/subscribe">Please subscribe to begin</Link> : <Link className="font-lg" to="/subscribe">Please subscribe to begin</Link>}}
    header={header}
    dataSource={pairs}
    renderItem={renderItem}
  />
}

export default AppIntegrationsList;
