import React from "react";
import {useHotkeys} from "react-hotkeys-hook";
import {useDispatch} from "react-redux";
import appActions from "@web/redux/app/actions";

export default function useSpacebarHold(hotKey = 'space') {
  const dispatch = useDispatch();
  const [hold, setHold] = React.useState(false);

  React.useEffect(() => {
    dispatch(appActions.setSpacebarHold(hold));
  }, [hold, dispatch]);

  useHotkeys(hotKey, (e, handlerEvent) => {
    if (e.shiftKey) return;
    if (e.ctrlKey) return;
    if (e.type === "keyup") {
      // dispatch(() => appActions.setSpacebarHold(false));
      return setHold(false);
    }
    if (e.type === "keydown" && e.repeat) {
      // dispatch(() => appActions.setSpacebarHold(true));
      return setHold(true);
    }
  }, {keydown: true, keyup: true});

  return [hold];
}
