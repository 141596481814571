import React from "react";
import PropTypes from "prop-types";
import {
  AccountIcon,
  AttachmentIcon,
  CalendarIcon, DownArrowOutlined,
  EmailIconOutlined,
  LayersIcon,
  PaymentIcon,
  TodoIcon,
  TruckIcon, UserOutlined,
} from "@web/assets/icons";
// import RecommendedTodo from "./RecommendedTodo";
import {Tabs} from "antd";
import {useSelector} from "react-redux";
import TimelinePaymentsCard from "./TimelinePaymentsCard";
// import ContentData from "@web/ui/containers/ContentData";
import TimelineEmail from "./TimelineEmail";
import DetailData from "@web/ui/containers/DetailData";
// import ActivityPaymentData from "@web/ui/containers/ActivityPaymentData";
import GetDocumentFirestore from "@web/ui/containers/GetDocumentFirestore";
import TimelineFilesCard from "./TimelineFilesCard";
import FileCard from "./FileCard";
import TimelineDeliveriesCard from "./TimelineDeliveriesCard";
import FileData from "@web/ui/containers/FileData";
// import ContactExtra from "./ContactExtra";

ActivityEventItemTabs.propTypes = {
  contextPath: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  primary: PropTypes.bool,
};

function ActivityEventItemTabs(props) {
  const event = useSelector((state) => state.Content.events[props.eventId]);
  const item = useSelector((state) => state.Content.data[event.itemPath]);
  // const contextItem = useSelector((state) => state.Content.data[props.contextPath]);
  // const content = useSelector((state) => state.Content);
  const [activeTab, setActiveTab] = React.useState(null);
  // console.log("ActivityEventItemTabs:event", {event, item, content, itemsList: props.itemsList});
  const tabs = [];
  // event.recommendedTodo &&
  // tabs.push({
  //   key: 'todo',
  //   label: <TodoIcon />,
  //   children: <RecommendedTodo
  //     key={props.eventId}
  //     eventId={props.eventId}
  //     defaultExpanded={props.primary}
  //     expand
  //     // expand={props.primary ? true : expanded}
  //     primary={props.primary}
  //   />,
  // });

  // const components = {
  //   messages: TimelineEmail,
  //   files: FileCard,
  //   payments: TimelinePaymentsCard,
  //   delivery: TimelineDeliveriesCard,
  //   threads: TimelineEmail,
  //   attachments: TimelineFilesCard,
  //   calendar: null,
  // }
  //
  // const itemTabs = props.itemsList.map(([itemPath]) => {
  //   return {
  //     key: itemPath.split("/")[2],
  //     label: itemPath.split("/")[2],
  //     children: <DetailData path={itemPath} component={components[itemPath.split("/")[2]]} />
  //   };
  // })
  // tabs.push({
  //   key: 'members',
  //   label: <UserOutlined />,
  //   children: <ContactExtra path={event.itemPath} contact={props.contextPath?.includes("/contacts/") ? contextItem : null} />,
  // });

  !!event.itemPath.includes("/messages/") &&
  tabs.push({
    key: 'email',
    label: <EmailIconOutlined />,
    children: <DetailData path={event.itemPath} hideSubject component={TimelineEmail} />,
    // children: <TimelineEmail
    //   path={event.itemPath}
    //   date={event.date}
    //   hideSubject
    //   showAttachmentCount={true}
    //   // icon={DomainIcon}
    // />,
  // <TimelineEmail
  //     path={event.itemPath}
  //     date={item?.date}
  //     hideSubject
  //     showAttachmentCount={true}
  //     // icon={DomainIcon}
  //   />,
  });

  !!event?.isMeetingRelated &&
  tabs.push({
    key: 'calendar',
    label: <CalendarIcon />,
    children: null,
  });

  !!item?.attachments || !!event.attachments &&
  tabs.push({
    key: 'attachments',
    label: <AttachmentIcon />,
    children: <FileData component={TimelineFilesCard} path={event.attachments?.[0]?.path} />,
  });

  if (!!event.itemPath.includes("/files/") || !!item?.filePaths) {
    tabs.push({
      key: 'files',
      label: <LayersIcon />,
      children: <DetailData path={event.itemPath} component={FileCard} />,
    });
  }

  event.paymentPath &&
  tabs.push({
    key: 'payments',
    label: <PaymentIcon />,
    children: <GetDocumentFirestore path={event.paymentPath} dataField="payment">
      <TimelinePaymentsCard />
    </GetDocumentFirestore>,
  // children: <ContentData
  //     path={props.path}
  //     dataField="payment"
  //     date={props.date}
  //     primary={props.primary}
  //     collapse={false}
  //     component={TimelinePaymentsCard}
  //   />,
  });

  event.deliveryPath === true &&
  tabs.push({
    key: 'delivery',
    label: <TruckIcon />,
    children: <DetailData path={event.deliveryPath} component={TimelineDeliveriesCard} />,
  });

  // console.log("ActivityEventItemTabs:tabs", {props, tabs, item});

  return tabs.length &&
    <Tabs
      size="small"
      style={{maxWidth: "90%"}}
      activeKey={activeTab}
      onChange={setActiveTab}
      className={activeTab ? "": "tabs-bar-no-underline"}
      // items={tabs}
      items={tabs}
      tabBarExtraContent={{
        left: <div/>,
        // right: <Button ghost><DownArrowOutlined /></Button>,
      }}
    />;
}

export default ActivityEventItemTabs;
