import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import ContactDetailsListItemMeta from "./ContactDetailsListItemMeta";
import RecommendedTodo from "./RecommendedTodo";
import ActivityIconStrip from "./ActivityIconStrip";
import {Tabs} from "antd";
import {EmailIconOutlined, PaymentIcon, TodoIcon, TruckIcon} from "@web/assets/icons";
import ActivityEventItemTabs from "./ActivityEventItemTabs";
import Toggle from "./Toggle";

/**
 * @typedef {Object} web.client.ContentItemActivity
 * @property {string} activityPath
 * @property {omit<models.Activity.ActivityEvent, "recommendedTodo", "reminder", "update">} event
 * @property {string} title
 * @property {string} goal
 * @property {models.Activity.ActivityEventRecommendedTodo} [recommendedTodo]
 * @property {reminder: models.Activity.ActivityEventReminder} [reminder]
 * @property {number} date
 * @property {string} serviceId
 */


TimelineItemEventsList.propTypes = {
  contextPath: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  serviceId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node),
  itemsOnly: PropTypes.bool,
};

function TimelineItemEventsList(props) {
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    setExpanded(props.showDetails);
  }, [props.showDetails]);

  const eventIdList = useSelector((state) => {
    return state.Content.activity?.[props.contextPath]?.eventsByItem?.[props.path];
  });
  // const itemsList = useSelector((state) => {
  //   return state.Content.activity?.[props.contextPath]?.itemsList;
  // });

  const event = useSelector((state) => {
    // eventIdList.map((eventId) => console.log("TimelineItemEventsList:events:", state.Content.events[eventId]));
    return state.Content?.events[eventIdList[0]];
  });

  const onClick = React.useCallback((e) => {
    // console.log("TimelineItemEventsList:onClick", expanded);
    e && e.preventDefault();
    e && e.stopPropagation();
    props.onClick &&
    props.onClick(e);
    setExpanded(!expanded);
    // return false;
  }, [expanded]);

  if (!eventIdList?.length) {
    // console.log("TimelineItemEventsList:No events found for", props.path);
    return props.children;
  }

  return <React.Fragment>
    {eventIdList.map((eventId, index) => {
      return <ContactDetailsListItemMeta
        key={eventId}
        path={props.path}
        eventId={eventId}
        displayName={props.displayName}
        name={props.name}
        email={props.email}
        contactIcon={props.contactIcon}
        primary={props.primary}
        onClick={onClick}
      />
    })}
    <div style={{marginLeft: '60px'}} >
      {/*<Tabs*/}
      {/*  items={[{*/}
      {/*    key: 'todo',*/}
      {/*    label: <TodoIcon />,*/}
      {/*    children: <RecommendedTodo*/}
      {/*      key={eventIdList[0]}*/}
      {/*      eventId={eventIdList[0]}*/}
      {/*      defaultExpanded={props.primary}*/}
      {/*      expand*/}
      {/*      // expand={props.primary ? true : expanded}*/}
      {/*      primary={props.primary}*/}
      {/*    />},*/}
      {/*    {key: 'delivery', label: <TruckIcon/>},*/}
      {/*    {key: 'payments', label: <PaymentIcon/>},*/}
      {/*    {key: 'email', label: <EmailIconOutlined/>},*/}
      {/*  ]}*/}
      {/*/>*/}
      {!props.itemsOnly && <RecommendedTodo
        key={eventIdList[0]}
        eventId={eventIdList[0]}
        defaultExpanded={props.primary}
        expand
        // expand={props.primary ? true : expanded}
        primary={props.primary}
      />}
      {/*<div style={{marginTop: "10px"}}>*/}
      {/*  <ActivityIconStrip size="large" eventId={eventIdList[0]} />*/}
      {/*</div>*/}
    </div>
    <div style={{marginLeft: '60px'}} className="bgxs">
      <ActivityEventItemTabs
        contextPath={props.contextPath}
        eventId={eventIdList[0]}
      />
      {/*<Toggle trigger={<ActivityIconStrip size="large" eventId={eventIdList[0]} />} hideTriggerOnExpand>*/}
      {/*  <ActivityEventItemTabs eventId={eventIdList[0]} />*/}
      {/*</Toggle>*/}
    </div>
    {expanded &&
      <div style={{marginLeft: '60px'}}>{props.children}</div>}
  </React.Fragment>;
}

export default TimelineItemEventsList;
