import React from 'react';
import {useInView} from 'react-intersection-observer';
import PropTypes from "prop-types";

WithCardVisibilityObserver.propTypes = {
  component: PropTypes.node.isRequired,
  threshold: PropTypes.number,
  delay: PropTypes.number,
};

export default function WithCardVisibilityObserver({component, threshold = 0.5, delay = 500, ...props}) {
  const [ref, isInView, entry] = useInView({
    threshold, delay,
  });

  // React.useEffect(() => {
  //   props.onInView?.(isInView);
  //   console.log('isInView:', props.path, isInView);
  // }, [isInView, props.path]);

  return <section ref={ref} style={{width: '100%'}}>
    {React.createElement(component, {
      ...props,
      isActive: isInView,
    })}
  </section>
}
