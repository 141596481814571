import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Space, Steps, Table, Tag} from "antd";
import {CheckIcon, DownArrowOutlined, KanbanIcon} from "@web/assets/icons";
// import Toggle from "./Toggle";
import MarkdownTimeline from "./MarkdownTimeline";
import {AppContext} from "@web/hooks/context";
// import StatusActions from "./StatusActions";
// import {useSelector} from "react-redux";
import {useLocation} from "wouter";
import KanbanTask from "./KanbanTask";
import {useSelector} from "react-redux";
// import DraftEditorManager from "./DraftEditorManager";

UserTaskProgress.propTypes = {
  collapsed: PropTypes.bool,
  userTask: PropTypes.object.isRequired,
  agentAnswer: PropTypes.object,
  finished: PropTypes.bool.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onAddContextPath: PropTypes.func.isRequired,
  onRemoveContextPath: PropTypes.func.isRequired,
  onClearEmailDraftAction: PropTypes.func.isRequired,
};

function UserTaskProgress(props) {
  const [, user] = React.useContext(AppContext);
  const [location, setLocation] = useLocation();
  const [view, setView] = React.useState("");
  const assistantPath = ["user", user.uid, "lines", "assistant"].join("/");
  const assistantName = useSelector((state) => state.Lines.data?.[assistantPath]?.assistantName);

  const onClickKanban = () => {
    setView("kanban");
  };
  // const activeServiceId = useSelector((state) => state.App.activeServiceId);
  // console.log("UserTaskProgress:activeServiceId", activeServiceId);
  if (!props.userTask) {
    return null;
  }

  const sortedSteps = Object.values(props.userTask?.steps || {}).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
  const numSteps = sortedSteps.length || false;

  console.log("UserTaskProgress:sortedSteps", sortedSteps);

  return view === "kanban" ?
    <KanbanTask
      bordered={false}
      task={props.userTask}
      width={1200}
      steps={sortedSteps.map((step) => {
        return {
          key: step.step,
          step: step.step,
          isComplex: step.isComplex ? "Yes" : "No",
          notes: step.stepNotes,
          agent: step.agent || assistantName,
          ready: step.ready ? "Yes" : "No",
          inProgress: step.inProgress ?? false,
          inReview: step.inReview ?? false,
          isCompleted: step.isCompleted ?? false,
          expandField: "notes",
        }
      })}
      columns={[
        {title: "Step", dataIndex: "step", key: "step", width: 300, render: (val) => <label className="font-lg ai-text">{val}</label>},
        // {title: "Notes", dataIndex: "notes", key: "notes"},
        {title: "Complex", dataIndex: "isComplex", key: "isComplex", width: 100, render: (val) => <Tag color={val === "Yes" ? "green" : "red"}>{val}</Tag>},
        // Table.SELECTION_COLUMN,
        {title: "Agent", dataIndex: "agent", key: "agent", width: 100},
        {title: "Ready", dataIndex: "ready", key: "ready", width: 100},
        {title: "In Progress", dataIndex: "inProgress", key: "inProgress"},
        {title: "In Review", dataIndex: "inReview", key: "inReview"},
        {title: "Done", dataIndex: "isCompleted", key: "isCompleted",  render: (_, record) => (
            <Space size="middle">
              <Button ghost><CheckIcon/></Button>
            </Space>
          ),},
      ]}
    /> :
    <Card
    bordered={false}
    className={`card-rounded card-simple extra-round${props.collapsed ? "" : " card-h-600"} card-body-scroll`}
    title={location !== "/line" ? <div className="space space-col">
      <label className="font-lg bolded">{props.userTask?.goal || "Task"}</label>
      <span className="ai-text font-thin">{props.userTask.summary}</span>
    </div> : <Button className="icon-btn font-lg" size="large" shape="round" onClick={onClickKanban}><KanbanIcon /> Kanban</Button>}
    // extra={!props.collapsed && <Button ghost onClick={props.toggleCollapse}><DownArrowOutlined /></Button>}
  >
    {/*<Typography.Text>{props.userTask.summary}</Typography.Text>*/}
    {!props.collapsed ?
      <Steps
        direction="vertical"
        // progressDot
        current={sortedSteps.findIndex((taskStep) => !taskStep.isCompleted)}
        items={sortedSteps.map((taskStep) => {
          const status = taskStep.isCompleted ? "Completed" : taskStep.isSkipped ? "Skipped" : "In Progress";
          return {
            title: <span className={`bolded font-lg ${taskStep.isSkipped ? "muted" : taskStep.isCompleted ? "" : ""}`}>{taskStep.step}</span>,
            subTitle: taskStep.stepNotes && status,
            description: <div className="space space-col">
              {
                // <Toggle
                //   trigger={<label className="green-color">{taskStep.contextList?.length || false} Reference{taskStep.contextList?.length > 1 && "s"}</label>}
                //   showExpandCaret={true}
                //   hideTriggerOnExpand={true}
                // >
                <MarkdownTimeline
                  markdown={`${taskStep.stepNotes || status}  
                  ${taskStep.contextList?.map?.((listItem) => {
                    return listItem.startsWith("http") ?
                      listItem :
                      `[related](#source-link?path=user/${user.uid}/${listItem})`;
                  }).join("\n") || ""}`}
                  // action={[<StatusActions
                  //   path={props.path}
                  //   initialStatus={""}
                  //   iconClassName="green-icon"
                  //   actions={["remind", "done", "dismiss"]}
                  //   size="middle"
                  // />]}
                />
                // </Toggle>
              }
            </div>,
            status: (taskStep.isCompleted || taskStep.isSkipped) ? "finish" : "process",
          };
        })}
        // items={[
        //   {
        //     title: 'Finished',
        //     description: 'This is a description.',
        //   },
        //   {
        //     title: 'In Progress',
        //     description: 'This is a description.',
        //   },
        //   {
        //     title: 'Waiting',
        //     description: 'This is a description.',
        //   },
        // ]}
      /> : numSteps > 1 && <label className="green-color" onClick={props.toggleCollapse}>{`${numSteps} Steps`}</label>
    }
    {/*<div style={{display: collapsed ? "none" : "block"}}>*/}
    {/*  {props.userTask?.actions?.map((action) => {*/}
    {/*    switch (action.actionComponent) {*/}
    {/*      case "draftEmail": {*/}
    {/*        return <DraftEditorManager*/}
    {/*          agentId={"ai-progress"}*/}
    {/*          draftPath={action.draftPath}*/}
    {/*          onAddContextPath={props.onAddContextPath}*/}
    {/*          onRemoveContextPath={props.onRemoveContextPath}*/}
    {/*          onClearActiveEmailDraft={props.onClearEmailDraftAction}*/}
    {/*        />*/}
    {/*      }*/}
    {/*      default: {*/}
    {/*        return null;*/}
    {/*      }*/}
    {/*    }*/}
    {/*  })}*/}
    {/*</div>*/}
  </Card>
}

export default UserTaskProgress;
