import React from "react";
import PropTypes from "prop-types";
import MarkdownTimeline from "./MarkdownTimeline";
import useChatStreamListener from "@web/hooks/useChatStreamListener";
import ChatProgressData from "@web/ui/containers/ChatProgressData";
import DraftEditorManager from "./DraftEditorManager";
import {useSelector} from "react-redux";
import {chatAppAgentIds} from "@oven/app-thescribe/src/dashboard.config";
import UserTaskProgressManager from "./UserTaskProgressManager";
import {Avatar, Col, Comment, Row} from "antd";
import {FeatherIcon} from "@web/assets/icons";
import aiActions from "@web/redux/ai/actions";
import StreamingDraftListener from "./StreamingDraftListener";
import ChatPrompt from "./ChatPrompt";
import {useLocation} from "wouter";
import ActiveDetailSummary from "./ActiveDetailSummary";

ChatFeedback.propTypes = {
  agentId: PropTypes.string,
  promptText: PropTypes.string,
  onAddContextPath: PropTypes.func,
  onRemoveContextPath: PropTypes.func,
  chat: PropTypes.object,
  path: PropTypes.string,
  collectionPath: PropTypes.string,
  hidePrompt: PropTypes.bool,
  hideProgress: PropTypes.bool,
};

function ChatFeedback(props) {
  const [location] = useLocation();
  const activeDraftPath = useSelector((state) => state.AI.draft?.draftPath);
  const activeUserTaskPath = useSelector((state) => state.AI.userTask?.path);
  const [chatStream, chatPath,, resetStream] = useChatStreamListener(props.agentId, props.collectionPath);
  const [collapsed, setCollapsed] = React.useState(false);

  React.useEffect(() => {
    setCollapsed(false);
    // resetStream();
  }, [props.promptText]);

  const toggleCollapse = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return <div className="chat-feedback-view">
    <Row className="w-full">
      <Col xs={24} sm={24} md={24} lg={20} xl={22} xxl={22} className="overscroll-contain">
        {(props.promptText || chatStream?.prompt?.text) &&
          !props.hidePrompt &&
          <ChatPrompt
            path={props.path}
            chat={chatStream}
            onClick={toggleCollapse}
            primary
            collapsed={collapsed}
          />
        }
        {!collapsed &&
          chatStream?.answers?.length &&
          <section className="space space-end-y">
            <div className="space space-col">
              <Comment
                avatar={<Avatar icon={<FeatherIcon className="gold-icon" style={{position: 'relative', top: 4}} />} size="large" className="transparent-bg" />}
                content={
                chatStream.answers.map((answer) =>
                  !!answer.text &&
                  !!answer.toolCalls &&
                  !chatAppAgentIds.includes(answer.agentId) &&
                  <MarkdownTimeline
                    key={answer.generated}
                    markdown={answer.text}
                  />)
                }
              />
            </div>
          </section>
        }
        <ActiveDetailSummary />
        {!!activeDraftPath ?
          <div style={{width: '990px', maxWidth: '100%', margin: '0 auto 10px'}}>
            <DraftEditorManager
              agentId={props.agentId}
              draftPath={activeDraftPath}
              onAddContextPath={props.onAddContextPath}
              onRemoveContextPath={props.onRemoveContextPath}
              onClearActiveEmailDraft={aiActions.clearActiveEmailDraft}
            />
          </div> :
          !!chatStream && <StreamingDraftListener chatStream={chatStream} />
        }
        {!!activeUserTaskPath &&
          location !== "/line" &&
          <div style={{width: '990px', maxWidth: '100%', marginBottom: '10px'}}>
            <UserTaskProgressManager
              collapsed={true}
              agentId={props.agentId}
              path={activeUserTaskPath}
              onAddContextPath={props.onAddContextPath}
              onRemoveContextPath={props.onRemoveContextPath}
            />
          </div>
        }
        {!!chatPath && !props.hideProgress && <ChatProgressData path={chatPath} />}
      </Col>
    </Row>
  </div>
}

export default ChatFeedback;
