import React, {useCallback} from "react";
// import appActions from "@web/redux/app/actions";
// import contentActions from "@web/redux/content/actions";
// import {useDispatch, useSelector} from "react-redux";
import {DownArrowOutlined} from "@web/assets/icons";
import {Dropdown} from "antd";
// import {useSelector} from "react-redux";
// import {SourcesContext} from "@web/hooks/context";

function ServiceSelector(props) {
  return <Dropdown.Button
    className="unblock"
    placement="bottomLeft"
    size="large"
    overlayClassName="dropdown-overlay"
    menu={{items: props.items, onClick: props.onClick}}
    icon={
      <DownArrowOutlined size={24} style={{position: "relative", top: "4px"}} />
    }
  >
    <span className="font-lg">{props.text}</span>
  </Dropdown.Button>
}

export default ServiceSelector;
