import React from "react";
import PropTypes from "prop-types";
import ContactInfoCard from "./ContactInfoCard";
import {useSelector} from "react-redux";
import {Divider, Empty, List} from "antd";
import ContactsListItem from "./ContactsListItem";
import {AppContext} from "@web/hooks/context";
import Toggle from "./Toggle";
import ItemData from "@web/ui/containers/ItemData";

ContactExtra.propTypes = {
  path: PropTypes.string,
  contact: PropTypes.object,
};

function ContactExtra(props) {
  const [, user] = React.useContext(AppContext);
  // const item = useSelector((state) => {
  //   return state.Content.data?.[props.path];
  // });
  const memberUserIdList = useSelector((state) => {
    return state.Content.activity?.[props.path]?.membersList;
  });

  console.log("ContactExtra:render:", props.path, memberUserIdList);

  // if (!memberUserIdList?.length) {
  //   return null;
  // }

  return <div className="space space-col" style={{width: '100%', minHeight: (memberUserIdList * 60) + 300}}>
    {!!props.contact && <ContactInfoCard path={props.path} contact={props.contact} />}
    {/*{props.path.includes("/contacts/") && <ContactInfoCard path={props.path} contact={item} />}*/}
    {/*{!!props.contact && !!props.path && <Divider className="small-top small-bottom"/>}*/}
    <List
      className="list-item-no-divider"
      dataSource={
        props.path ?
          (memberUserIdList || []).filter((memberUserId) => memberUserId !== props.path.split("/").pop()) :
          memberUserIdList
      }
      renderItem={(memberUserId) =>
        <Toggle
          hideTriggerOnExpand
          showExpandCaret
          trigger={
            <ContactsListItem
              key={memberUserId}
              path={["user", user.uid, "contacts", memberUserId].join("/")}
              onClick={(data, e) => {
                console.log("onClick");
              }}
            />}
          >
          <ItemData
            path={["user", user.uid, "contacts", memberUserId].join("/")}
            component={ContactInfoCard}
            dataField="contact"
          />
      </Toggle>}
      locale={{
        emptyText: <div className="space space-center-y">
          <Empty
            description=""
            imageStyle={{display: 'none'}}
          />
        </div>,
      }}
    />
    {/*{memberUserIdList?.length && memberUserIdList.map((memberUserId) => <span key={memberUserId}>{memberUserId}</span>)}*/}
  </div>
}

const MemoizedComponent = React.memo(ContactExtra, (prevProps, nextProps) => {
  return prevProps.path === nextProps.path;
});

export default MemoizedComponent;
