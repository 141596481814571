import React, {useContext} from "react";
import {Button, Carousel} from "antd";
import {auth, ga} from "@web/lib/firebase";
import {WindowContext} from '@web/hooks/context'
import backgroundActions from "@web/redux/background/actions";
import SplashCard from './SplashCard'
import {DownArrowOutlined, RightArrowOutlined} from "@web/assets/icons";
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "./Loading";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "wouter";
import useLocalStorage from "@web/hooks/useLocalStorage";
import PropTypes from "prop-types";
import DashboardFullRow from "./DashboardFullRow";
import VideoLink from "./VideoLink";

Splash.propTypes = {
  initialSlideKey: PropTypes.string,
}

export default function Splash(props) {
  const dispatch = useDispatch();
  const [,, loadingUser] = useAuthOven(auth);
  const [isMobile] = useContext(WindowContext);
  const [location] = useLocation();
  const startAnimation = React.useCallback(() => dispatch(backgroundActions.startAnimation()), [dispatch]);
  const pauseAnimation = React.useCallback(() => dispatch(backgroundActions.pauseAnimation()), [dispatch]);
  const videoModalOpen = useSelector((state) => state.App.videoModal?.open);
  const slider = React.useRef({});

  const activeSlideKeys = [
    "hotline",
    // "phoneVerify",
    "home",
    // "signup",
    "invite",
    "subscribe",
  ];

  const initialSlideIndex = (activeSlideKeys.indexOf(props.initialSlideKey) >= 0) ?
    activeSlideKeys.indexOf(props.initialSlideKey) :
    0;

  const [seeGenesis, setSeeGenesis] = useLocalStorage('seeGenesis')
  const [disableNavigation, setDisableNavigation] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(initialSlideIndex);
  console.log('Splash:currentIndex:', {currentIndex, initialSlideIndex});

  React.useEffect(() => {
    if (location === '/genesis') setSeeGenesis(1)
  }, [location]);

  const navigateNext = React.useCallback((e) => {
    slider.current?.next()
    ga.click(e)
  }, [slider, slider.current, currentIndex]);

  if (loadingUser) return <Loading />;

  const slides = {
    hotline: <SplashCard.Hotline pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={0} disableNavigation={setDisableNavigation} key="hotline" />,
    // phoneVerify: <SplashCard.PhoneVerify pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={1} key="phoneVerify" />,
    home: <SplashCard.Home pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={1} key="home" />,
    // signup: <SplashCard.Signup pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={3} disableNavigation={setDisableNavigation} key="signup" />,
    invite: <SplashCard.Invite pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={2} next={navigateNext} disableNavigation={setDisableNavigation} key="invite" />,
    subscribe: <SplashCard.Subscribe pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={3} key="subscribe" />,
    // app: <SplashCard.Apps pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={1} />,
    // assistant: <SplashCard.Assistant pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={2} />,
    // insights: <SplashCard.Insights pauseAnimation={pauseAnimation} startAnimation={startAnimation} index={3} />,
  };

  return <section style={{position: 'relative'}}>
    <Carousel
      ref={(ref) => slider.current = ref}
      initialSlide={initialSlideIndex}
      className="splash-carousel splash-carousel-dots"
      adaptiveHeight
      prevArrow={null}
      nextArrow={!isMobile && !disableNavigation && <Button icon={<RightArrowOutlined />} />}
      // arrows={!isMobile && !disableNavigation}
      arrows={false}
      swipe={!disableNavigation}
      dots={!disableNavigation}
      accessibility={!isMobile && !disableNavigation}
      beforeChange={() => console.log('beforeChange')}
      afterChange={(index) => setCurrentIndex(index)}
      onSwipe={() => null}
      lazyLoad="ondemand"
      infinite={false}
      draggable={false}
      waitForAnimate={true}
    >
      {activeSlideKeys.map((key) => slides[key])}
    </Carousel>
    <div style={{position: "fixed", top: "20px", right: "0", width: '100%'}}>
      <DashboardFullRow>
        <div className="space space-center-y space-center-x">
        </div>
      </DashboardFullRow>
    </div>
    <div style={{background: "black", width: "80%", height: "60px", position: "absolute", bottom: videoModalOpen ? "-100px" : "-20px", left: "50%", transform: "translateX(-50%)", borderRadius: "40px 40px 0 0", paddingTop: '15px'}}>
      <VideoLink className="text-color space space-center-y space-center-x" url="https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
      ><DownArrowOutlined size={28}/></VideoLink>
    </div>
  </section>
}
