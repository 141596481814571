import React, {useContext} from "react";
import {Typography, List, Card, Empty} from "antd";
import ItemActivityCard from "./ItemActivityCard";
import ItemActivityData from "@web/ui/containers/ItemActivityData";
import PropTypes from "prop-types";
// import WithActiveContentObserver from "@web/ui/containers/WithActiveContentObserver";
// import ActivityFacets from "./ActivityFacets";
// import {useDispatch} from "react-redux";
// import pick from "lodash/pick";
// import appActions from "@web/redux/app/actions";
import {WindowContext} from "@web/hooks/context";
import Pager from "@web/ui/containers/Pager";
import Loading from "./Loading";
import LineConnector from "./LineConnector";
import GroupTitle from "./GroupTitle";
import ContactsListItem from "./ContactsListItem";
import TaskListItem from "./TaskListItem";
// import {getServiceId, getServiceIdFromPath} from "@web/redux/app/reducers";

TaskList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

export default function TaskList(props) {
  const [,, height] = useContext(WindowContext);

  return <div
    id="services-tasks-scroll-target"
    style={{height: height - 60, overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
    <Pager
      height={height - 60}
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-tasks-scroll-target"
    >
      <List
        header={
          <Card
            bordered={false}
            style={{width: "100%", margin: "0", height: 60}}
            // title={}
            // title={
            //   <ServiceData serviceId="reminders" component={RemindersList} />
            // <GroupTitle
            //   title="Activity"
            //   style={{paddingLeft: "20px"}}
            // >
            //   <div className="space space-col" style={{width: "100%"}}>
            //     <div style={{width: "90%", paddingTop: "100px"}}>
            //     {/*<ContentFilter />*/}
            //     </div>
            //     {/*<FeedSelector />*/}
            //     <ServiceData serviceId="reminders" component={RemindersList} />
            //   </div>
            // </GroupTitle>
            className="card-simple card-rounded card-no-header card-transparent card-no-separator card-slim-title"
          ></Card>
      }
        className="services-contacts-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <TaskListItem
            key={path}
            path={path}
            date={date}
          />}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
          </Empty>
        }}
      />
    </Pager>
  </div>
}
