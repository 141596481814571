/** @namespace chatStream */
import {useSelector} from "react-redux";

/**
 * @function useChatStreamListener
 * @memberof chatStream
 * @param {string} agentId
 * @param {string} collectionPath
 * @param {string} [chatPath]
 * @returns {[chat:models.Chat.Data, collectionPath:string]}
 */
function useChatStreamListener(agentId, collectionPath, chatPath) {
  /** @type {[models.Chat.Data, string]} */
  const stream = useSelector((state) => {
    // console.log("useChatStreamListener:state", collectionPath);
    // console.log("useChatStreamListener:state", state.AI[agentId]);
    // console.log("useChatStreamListener:state", state.AI[agentId]?.[collectionPath]);
    return state.AI[agentId]?.[collectionPath];
  });

  // console.log("useChatStreamListener:", stream, agentId, collectionPath, chatPath);

  if (stream && chatPath) {
    return (stream[1] === chatPath) ?
      [stream?.[0], stream?.[1], stream?.[2]] :
      [];
  }
  return [stream?.[0], stream?.[1], stream?.[2]];
}

export default useChatStreamListener;
