import React, {useContext} from "react";
import {Drawer} from "antd";
import {WindowContext} from "@web/hooks/context";
import {LeftArrowOutlined, XIcon} from "@web/assets/icons";
import PropTypes from "prop-types";

WithDrawer.propTypes = {
  drawerKey: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  placement: PropTypes.string.isRequired,
  hideCloseIcon: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  header: PropTypes.node,
  footer: PropTypes.node,
  maskClosable: PropTypes.bool,
  icon: PropTypes.node,
  destroyOnClose: PropTypes.bool,
  ghost: PropTypes.bool,
  children: PropTypes.node,
};

function WithDrawer(props) {
  const [, windowWidth, windowHeight] = useContext(WindowContext)

  return <Drawer
    className={
    `detail-drawer ${props.ghost ? 'transparent-bg' : ''} ${props.header ?
      'with-header' :
      'without-header'
    }`}
    key={props.drawerKey}
    placement={props.placement}
    height={props.height ?? windowHeight}
    width={props.width ?? windowWidth}
    open={props.open}
    onClose={props.onClose}
    destroyOnClose={props.destroyOnClose ?? true}
    closeable={!props.hideCloseIcon}
    closeIcon={
    !props.hideCloseIcon ?
      props.closeIcon || <XIcon /> :
      null
    }
    maskClosable={!!props.maskClosable}
    getContainer={false}
    mask={false}
    keyboard
    title={props.header ?? null}
    footer={props.footer ?? null}
  >
    {/*<section*/}
    {/*  id="detail-drawer-content-scroll"*/}
    {/*  style={{*/}
    {/*    width: '100%',*/}
    {/*    height: '100%',*/}
    {/*    position: 'relative',*/}
    {/*    // overflowY: 'auto',*/}
    {/*    overflowX: 'visible', // todo why does changing this to hidden wreck fps of sliders?*/}
    {/*    overscrollBehavior: 'contain',*/}
    {/*  }}*/}
    {/*>*/}
    {props.children}
    {/*</section>*/}
  </Drawer>
}

export default WithDrawer;
