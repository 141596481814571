import {auth, firebaseApp} from "./firebase";
import {getFunctions, httpsCallable} from 'firebase/functions';
import now from "lodash/now.js";

const chatUrl = () => {
  return (process.env.NODE_ENV === "production") ?
    "https://ai-chat-7zujc5azra-uw.a.run.app" :
    "http://127.0.0.1:5001/admin-85663/us-west1/ai-chat";
}

/**
 * @summary Stream chunks to chat
 * @param {{prompt:object, [answers]:object[]}} chat
 * @param {Response} res
 * @param {function({chatId:string,chat:object,path:string}):void} callback
 * @return {Promise<void>}
 */
async function streamToChat(chat, res, callback) {
  // const dispatch = useDispatch();
  const agentId = res.headers.get("x-agent-id");
  const chatId = res.headers.get("x-chat-id");
  const path = res.headers.get("x-chat-path");
  const collectionPath = res.headers.get("x-coll-path");

  console.log("streamToChat:headers:x-coll-path:", collectionPath);

  if (!agentId || !chatId || !path) {
    throw new Error("Missing agentId, chatId, or path");
  }

  // const answer = {text: ""};
  chat.answers = [];
  let answerIndex = 0;

  const reader =
    res.body
    ?.pipeThrough(new TextDecoderStream())
    .getReader();

  while (reader) {
    console.log("Streaming...");
    let stream =
      await reader
      .read();

    if (stream.done) {
      console.log("Stream done", stream.done);
      callback({
        chatId,
        chat: {
          ...chat,
          updatedTs: now(),
        },
        path,
        done: stream.done,
      });
      break;
    }

    const chunks = stream.value
      .replaceAll(/^data: /gm, "")
      .split("\n")
      .filter((c) => Boolean(c.length) && c !== "[DONE]")
      .map((c) => JSON.parse(c));

    if (chunks) {
      // console.log("has chunks");
      for (let chunk of chunks) {
        // console.log("chunk!", chunk);
        switch (true) {
          case chunk.type === "content_block_delta":
          case chunk.type === "message_start":
          case chunk.type === "message_delta": { // Anthropic
            if (chunk.delta?.text) chat.answers[answerIndex].text = chat.answers[answerIndex].text + chunk.delta.text;
            if (chunk.index !== undefined) chat.answers[answerIndex].index = chunk.index;
            if (chunk.role) chat.answers[answerIndex].role = chunk.role;
            if (chunk.message?.id) chat.answers[answerIndex].originId = chunk.message.id;
            if (chunk.message?.model) chat.answers[answerIndex].model = chunk.message.model;
            if (chunk.delta?.stop_reason) {
              chat.answers[answerIndex] = {
                ...chat.answers[answerIndex] || null,
                stop: chunk.delta.stop_reason,
              };
              // todo: Warn if truncated stop_reason
              answerIndex += 1;
            }
            break;
          }
          case !!chunk.choices.length: { // OpenAI
            const [choice] = chunk.choices;
            const agentId = chunk.agentId;
            if (choice.delta.role === "assistant") { // New answer
              chat.answers.push({
                text: "",
                toolCalls: [],
                agentId,
                streamParams: chunk.streamParams || null,
              });
              answerIndex =
                chat.answers.length - 1;
            }

            if (choice.finish_reason) {
              chat.answers[answerIndex] = {
                ...chat.answers[answerIndex] || null,
                stop: choice.finish_reason
              };
              // todo: Handle finish_reason
              // todo: Warn if truncated stop_reason
              // todo: Start new answer by incrementing answer index
            }

            chat.answers[answerIndex] = {
              ...chat.answers[answerIndex],
              ...choice,
              originId: chunk.id,
              model: chunk.model,
              generated: chunk.created,
              text: (chat.answers[answerIndex]?.text || "") + (choice?.delta?.content || ""),
            };

            chat.answers[answerIndex].toolCalls =
              chat.answers[answerIndex].toolCalls
              .map((toolCall) => ({...toolCall}));

            choice.delta
            ?.tool_calls
            ?.forEach(({function: tool, index, ...restToolCall}) => {
              const existingFunction =
                chat.answers[answerIndex].toolCalls?.[index]?.function || null;

              console.log(
                "toolCall:delta:existingFunction:",
                {function: tool, index, ...restToolCall},
                existingFunction,
              );

              chat.answers[answerIndex].toolCalls[index] = {
                index,
                ...chat.answers[answerIndex]?.toolCalls?.[index] || null,
                ...restToolCall,
                function: {
                  ...existingFunction,
                  ...tool,
                  arguments: (existingFunction?.arguments || "") + tool.arguments,
                },
              };
            });
            break;
          }
          default:
            console.log("Unknown chunk", chunk);
            continue;
        }
        // console.log("debouncedCallback", {chatId, chat: {...chat, updatedTs: now()}, path});
        callback({chatId, chat: {...chat, updatedTs: now()}, path, collectionPath});
      }
    }
  }
}

export const api = {
  GET: async (path) => {
    console.log('get', path)
    const res = await fetch(encodeURI(path))
    const result = await res.json()
    return result.data
  },
  POST: async (path, body = null) => {
    console.log('post', path, body);
    const token = await auth.currentUser.getIdToken();
    const res = await fetch(encodeURI(path), {
      method: 'POST',
      headers: {
        ['Content-Type']: 'application/json',
        ['Authorization']: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    try {
      return await res.json()
    } catch(err) {
      console.log('api.POST', err)
      return err
    }
  },
  LINE: async (path, body = null) => {
    const endpoint = process.env.NODE_ENV === "production" ?
      "https://line-v1-7zujc5azra-uw.a.run.app" :
      "http://127.0.0.1:5001/admin-85663/us-west1/line-v1";

    const token = await auth.currentUser.getIdToken();
    const res = await fetch(encodeURI(endpoint + path), {
      method: 'POST',
      headers: {
        ['Content-Type']: 'application/json',
        ['Authorization']: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
    try {
      return await res.json()
    } catch(err) {
      console.log('api.POST', err)
      return err
    }
  },
  STREAM: async ({prompt, url}, streamCallback, onError) => {
    // const debouncedCallback = debounce((stream) => streamData(stream), 100, {leading: true, trailing: true});
    const token = await auth.currentUser.getIdToken();
    const res = await fetch(encodeURI(url || chatUrl()), {
      method: 'POST',
      headers: {
        ['Content-Type']: 'application/json',
        ['Authorization']: `Bearer ${token}`,
      },
      body: JSON.stringify({prompt}),
    }).catch((error) => error);

    if (res instanceof Error) {
      console.log("response:error:", res);
      return onError && onError(res);
    }
    await streamToChat({prompt}, res, streamCallback);
  },
  /**
   * Execute a callable function
   * @param {string} functionName - Example: "search-api-fulltext-call"
   * @param {object} data
   * @param {string} [region]
   * @returns {Promise<object>}
   */
  call: async (functionName, data, region = "us-west1") => {
    // const firebaseFunctions = process.env.NODE_ENV !== "production" ?
    //   getFunctions(firebaseApp, region) :
    //   functions;
    console.log("api.call:log:", functionName, data);
    return httpsCallable(getFunctions(firebaseApp, region), functionName)(data);
    // .catch((error) => error);
    // return httpsCallable(functions, functionName, options)(data);
  },
}
