import React from "react";
import PropTypes from "prop-types";
import {PhoneCallIcon} from "@web/assets/icons";
import voiceActions from "@web/redux/voice/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Tooltip} from "antd";

VoiceCallButton.propTypes = {
  // countryCode: PropTypes.string,
  to: PropTypes.string.isRequired,
  name: PropTypes.string,
  contactPath: PropTypes.string,
  numberType: PropTypes.string,
  size: PropTypes.oneOf(["small", "middle", "large"]),
  text: PropTypes.string,
  hideIcon: PropTypes.bool,
};

function VoiceCallButton(props) {
  const dispatch = useDispatch();
  const registered = useSelector((state) => state.Voice.registered);
  const connectCall = (params, memberInfo) => dispatch(voiceActions.connectCall(params, memberInfo));
  const onClickConnect = () => {
    // if (props.to?.length < 12 || !props.to.startsWith("+")) {
    //   console.log("Invalid phone number", props.to);
    //   return;
    // }
    // regex to ensure the phone number is valid (starts with +1 and is 12 characters long)
    const validNumber = /^\+1\d{10}$/;
    const numberToDial = (!validNumber.test(props.to)) ? "+1" + props.to : props.to;
    // const callParams = {To: props.to};
    const callParams = {To: numberToDial};
    const memberInfo = {
      name: props.name,
      contactPath: props.contactPath,
      numberType: props.numberType,
    };
    connectCall(callParams, memberInfo);
  }

  /**
   * Checks if the given value is valid as phone number
   * @param {string} number
   * @return {boolean}
   */
  const isValidPhoneNumber = (number) => {
    return /^[\d+\-() ]+$/.test(number);
  }

  const disableCallButton = () => {
    return (!registered || !isValidPhoneNumber(props.to));
  }

  if (props.to?.length < 10) {
    return null;
  }

  return <Tooltip
    title={`Line ${props.to}`}
    trigger={['hover']}
    placement="bottom"
  >
    <Button
      ghost
      disabled={disableCallButton()}
      onClick={onClickConnect}
      size={props.size || "large"}
      shape="circle"
      className="icon-btn icon-left"
      style={{marginTop: "-6px"}}
    >
      {props.hideIcon ? null : <PhoneCallIcon
        className="green-icon"
        style={{position: "relative", top: "2px"}}
      />}
      {!!props.text && <h6 className="underline">{props.text}</h6>}
    </Button>
  </Tooltip>
}

export default VoiceCallButton;
