import React from "react";
import {useDispatch, useSelector} from "react-redux";
import voiceActions from "@web/redux/voice/actions";
import InCallControls from "./InCallControls";
import useSpacebarHold from "@web/hooks/useSpacebarHold";
import {AppContext} from "@web/hooks/context";
import Hotline from "./Hotline";
import VoiceShortcutsTips from "./VoiceShortcutsTips";

function AssistantCallManager() {
  const dispatch = useDispatch();
  const [holdingSpacebar] = useSpacebarHold();
  const [,, userClaims] = React.useContext(AppContext);
  const activeCalls = useSelector((state) => state.Voice.activeCalls);
  const registering = useSelector((state) => state.Voice.registering);
  const registered = useSelector((state) => state.Voice.registered);
  const callError = useSelector((state) => state.Voice.callError);
  const [open, setOpen] = React.useState(false);
  const [number, setNumber] = React.useState("");
  const [dialing, setDialing] = React.useState(false);

  React.useEffect(() => {
    if (registered || registering) return;
    dispatch(voiceActions.register());
  }, []);

  React.useEffect(() => {
    if (activeCalls.length) return;
    if (holdingSpacebar) {
      !open && setOpen(true); // this should open lines drawer if not already open
      if (registered && !number && !!userClaims?.data?._h) { // calling assistant
        if (!dialing) {
          setDialing(true);
          dispatch(voiceActions.connectCall({To: userClaims.data._h}));
        }
      }
    } else {
      dialing && setDialing(false);
    }
  }, [holdingSpacebar, registered, dialing]);

  return <section className="space space-center-y space-center-x">
    {!!activeCalls.length ?
      <div className="space space-col left" style={{width: "100%", position: "relative", top: 40, left: -14}}>
        {activeCalls.map((callSid) => <InCallControls key={callSid} callSid={callSid} />)}
        <VoiceShortcutsTips />
      </div> :
      <Hotline />
    }
  </section>;
}

export default AssistantCallManager;
