const actions = {
  LOAD_USER_APPS: "LOAD_USER_APPS",
  LOAD_USER_APPS_SUCCESS: "LOAD_USER_APPS_SUCCESS",
  SYNC_USER_APPS_SUCCESS: "SYNC_USER_APPS_SUCCESS",
  LOAD_USER_APP_SERVICE: "LOAD_USER_APP_SERVICE",
  LOAD_USER_APP_SERVICE_SUCCESS: "LOAD_USER_APP_SERVICE_SUCCESS",
  SYNC_USER_APP_SERVICE_SUCCESS: "SYNC_USER_APP_SERVICE_SUCCESS",

  loadUserApps: () => {
    return (dispatch) => {
      dispatch({
        type: actions.LOAD_USER_APPS,
      });
    };
  },
  loadUserAppServices: (appPath) => {
    return (dispatch, getState) => {
      getState().Apps.data?.[appPath]?.servicesPaths?.forEach?.((servicePath) => {
        dispatch({
          type: actions.LOAD_USER_APP_SERVICE,
          appPath,
          servicePath,
        });
      });
    };
  },
};

export default actions;
