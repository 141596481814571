import React from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

DashboardRow.propTypes = {
  leftSpan: PropTypes.number,
  left: PropTypes.node,
  children: PropTypes.node,
};

function DashboardRow(props) {
  const leftSpan = props.leftSpan || 3;
  return <Row className="w-full">
    <Col xs={0} sm={0} md={0} lg={0} xl={leftSpan || 3} xxl={leftSpan || 3}>{props.left || null}</Col>
    <Col xs={24} sm={24} md={24} lg={24} xl={24 - leftSpan} xxl={24 - leftSpan} className="overscroll-contain">
      {props.children}
    </Col>
  </Row>
}

export default DashboardRow;
