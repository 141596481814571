import React from "react";
import PropTypes from "prop-types";
import {Redirect} from "wouter";

WildcardRoute.propTypes = {
  param: PropTypes.object.isRequired,
};

function WildcardRoute(props) {
  if (props.param?.length === 6) {
    return <Redirect to="/links" />;
  }
  return props.children;
}

export default WildcardRoute;
