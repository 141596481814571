import React, {useContext, useEffect} from 'react';
import PropTypes from "prop-types";
import {AppContext} from '@web/hooks/context'
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import appActions from "@web/redux/app/actions";
import Progress from "@ai-antd/page/Progress";

ContactDetailData.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function ContactDetailData({path, empty, children, ...props}) {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);

  const clearActiveDetail = React.useCallback(() => {
    dispatch(appActions.clearActiveDetail());
  }, []);

  const loadAllContent = React.useCallback(() => {
    dispatch(contentActions.loadAllContent({path}));
  }, [path]);

  const contact = useSelector((state) => {
    return state.Content?.data?.[path];
  });

  const activityList = useSelector((state) =>
    state.Content.activity?.[path]?.activityList);

  const itemsList = useSelector((state) =>
    state.Content.activity?.[path]?.itemsList);

  useEffect(() => {
    console.log(path);
    path && dispatch(contentActions.loadContent({path, serviceId: "contacts"}));
  }, [path]);

  if (!user?.uid) {
    return null;
  }

  if (!path) {
    return <Progress />;
  }

  return React.cloneElement(
    React.Children.only(children), {
      ...props,
      contextPath: path,
      activityList,
      itemsList,
      contact: contact || props.contact,
      onClose: clearActiveDetail,
      loadAllContent,
    });
}

export default ContactDetailData;
