import React from 'react';
import UserContentPreview from "@ai-antd/components/UserContentPreview";

/**
 * @summary useToolResult
 * @param {object} toolResult
 * @param {string} chatPath
 * @param {boolean} lazy
 * @param {number} [answerIndex]
 * @returns {[React.Component|null]}
 */
function useToolResult(toolResult, chatPath, lazy, answerIndex) {
  const ref = React.useRef(null);

  if (!toolResult) {
    return null;
  }
  if (toolResult.value.length < 5) {
    return null;
  }
  if (lazy) {
    return []
  }
  const parseJson = (jsonString) => {
    try {
      ref.current = JSON.parse(jsonString);
      return ref.current;
    } catch (e) {
      return {};
    }
  }

  /**
   * @summary getComponents
   * @param {string} toolResultValue
   * @returns {*[]}
   */
  const getComponentsFromJson = (toolResultValue) => {
    const data = ref.current || parseJson(toolResultValue);

    if (!data?.[0]?.result) return null;

    return Object
    .entries(data[0].result)
    .map(([path, data], index, list) =>
      path.startsWith("user/") &&
      <UserContentPreview
        key={`${path}-${index}`}
        path={path}
        href={path}
        data={data}
        index={index}
        length={list.length}
      />);
  }
  return getComponentsFromJson(toolResult.value);
}

export default useToolResult;
