import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Table, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import {LineChartIcon, TerminalIcon} from "@web/assets/icons";
import {SourcesContext} from "@web/hooks/context";
import DatasetTable from "./DatasetTable";

DatasetCard.propTypes = {
  dataset: PropTypes.object,
  sampleRecords: PropTypes.array,
  path: PropTypes.string,
  preview: PropTypes.bool,
};

function DatasetCard(props) {
  const dispatch = useDispatch();
  const {services} = useContext(SourcesContext);
  const setActiveDetail = () => {
    dispatch(appActions.setActiveDetail(
      services.datasets.key,
      {path: props.path, data: props.dataset},
      true),
      );
  };

  // const getTitle = () => {
  //   return <div className="space space-between">
  //     <span className="bolded capitalize">
  //       {props.dataset?.name}
  //     </span>
  //     <Tooltip
  //       title={<Card
  //         title="Launch AI Analyst"
  //         className="card-simple card-rounded"
  //         style={{width: 300}}
  //       >
  //         <Card.Meta
  //           description="Analyst is your powerful AI to explore data and find insights using natural language"/>
  //       </Card>}
  //       trigger={["hover"]}
  //     >
  //       <Button
  //         shape="round"
  //         onClick={setActiveDetail}
  //         className="green-color icon-btn"
  //         size={"middle"}
  //       >
  //         <TerminalIcon className="menu-icon" size={18} /> Launch Analyst
  //       </Button>
  //     </Tooltip>
  //   </div>;
  // }

  return props.preview ?
    <DatasetTable
      width={800}
      path={props.path}
      dataset={props.dataset}
      sampleRecords={props.sampleRecords}
    /> :
    <Card
      style={{width: 300, height: "100%"}}
      title={
      <div className="space space-center-y">
        <LineChartIcon className="muted-icons" />
        {props.dataset?.name}
      </div>
      }
      hoverable
      onClick={setActiveDetail}
      className="card-rounded card-simple card-transparent card-inline"
      bordered={false}
    >
      <span className="muted">{props.dataset?.description}</span>
    </Card>;
}

export default DatasetCard;
