import actions from "./actions";
// import reduce from "lodash/reduce.js";
// import uniq from "lodash/uniq.js";

const initState = {
  isLoading: false,
  errorMessage: false,
  data: {},
  // fields: null,
  // boards: {
  //   all: [],
  // },
  // lists: {
  //   all: [],
  // },
  // cards: {
  //   all: [],
  // },
  // actions: {
  //   all: [],
  // },
  // todos: {
  //   new: [],
  // },
};
// const parseActions = ({data, list: all}) => {
//   return reduce(data, ($acc, {data, type}, key) => {
//     const {card, board, list, listAfter, text} = data;
//     if (card) {
//       $acc[card.id] = ($acc[card.id] || []);
//       $acc[card.id].push(key);
//       $acc[board.id] = $acc[board.id] || [];
//       $acc[board.id].push(key);
//     } else console.log('No card', key);
//     // console.log('parseActions', Object.keys($acc));
//     return $acc;
//   }, {all});
// }

/**
 * @summary Reducer for apps
 * @param {object} state
 * @param {string} type
 * @param {{
 *  apps: FirestoreQueryItems,
 * }} payload
 * @param {string} collection
 * @return {*}
 */
export default function reducer(state = initState, {type, payload, error}) {
  switch (type) {
    case actions.SYNC_USER_APPS_SUCCESS:
      console.log(">>>>>>>reducer", type, payload);
      return {
        ...state,
        ...payload,
      };
    case actions.LOAD_USER_APPS_SUCCESS:
      console.log(">>>>>>>reducer", type, payload);
      return {
        ...state,
        ...payload.apps,
      };
    case actions.LOAD_USER_APP_SERVICE_SUCCESS:
      console.log(">>>>>>>reducer", type, payload);
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };
    case actions.SYNC_USER_APP_SERVICE_SUCCESS:
      console.log("SYNC_USER_APP_SERVICE_SUCCESS", payload);
      return {
        ...state,
        // data: {
        //   ...state.data,
        //   ...payload,
        // },
      };
    // case actions.LOAD_TASKS_SUCCESS:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       ...payload.boards.data,
    //       ...payload.lists.data,
    //       ...payload.cards.data,
    //       ...payload.actions.data,
    //     },
    //     boards: {all: uniq([...state.boards?.all, ...payload.boards.list])},
    //     lists: {all: uniq([...state.lists?.all, ...payload.lists.list])},
    //     cards: {all: uniq([...state.cards?.all, ...payload.cards.list])},
    //     actions: parseActions(payload.actions),
    //     error: null,
    //   };
    // case actions.LOAD_TODOS_SUCCESS:
    //   return {
    //     ...state,
    //     data: {...state.data, ...payload.items.data},
    //     todos: {
    //       ...state.todos,
    //       [payload.status]: payload.items.items,
    //     },
    //     error: null,
    //   };
    // case actions.SYNC_TASKS_SUCCESS:
    //   return {
    //     ...state,
    //     hasNew: {...state.hasNew, ...payload},
    //     error: null,
    //     // data: {...state.data, ...payload.data},
    //     // [collection]: collection === 'actions' ? state.actions : {all: uniq([...state[collection].all, ...payload.list])}, // todo fix handling of actions
    //   }
    // case actions.LOAD_TASKS_ERROR:
    //   return {
    //     ...state,
    //     error,
    //   };
    default:
      return state;
  }
}
