import React from 'react';
import PropTypes from 'prop-types';
import {List, Typography} from "antd";
import {PlusIcon} from "@web/assets/icons";
import FileCard from "./FileCard";
import {useSelector} from "react-redux";

TimelineFilesCard.propTypes = {
  files: PropTypes.array,
};

function TimelineFilesCard(props) {
  /** @type {models.File.Data[]} */
  const files =
    useSelector((state) => {
      return props.files?.map((path) => state.Content.data?.[path]);
    });

  console.log('TimelineFilesCard', {props, files});
  return <List
    bordered={false}
    title="Files"
    dataSource={files || []}
    grid={{
      gutter: 16,
      xs: 1,
      sm: 2,
      md: 2,
      lg: 3,
      xl: 3,
      xxl: 3,
    }}
    renderItem={(file, index) =>
      file ? <List.Item
        key={props.files[index]}
        // extra={<Button
        //   shape="round"
        //   className="icon-btn muted"
        //   onClick={() => dispatch(contentActions.loadContext(props.path))}
        // >
        //   <ActivityIcon className="muted-icon"/>
        //   {itemsList?.map(([path]) => <Tag>{path.split("/")[2]}</Tag>)}
        // </Button>}
      >
        <FileCard
          file={file}
          path={props.files[index]}
          grid
          bordered={false}
        />
      </List.Item> :
      null}
    locale={{
      emptyText: <div className="space space-center-y">
        <PlusIcon />
        &nbsp;
        <Typography.Text>
          No files
        </Typography.Text>
      </div>
    }}
  />
}

export default TimelineFilesCard;
