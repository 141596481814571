import React, {useContext} from "react";
import {Avatar, Comment} from "antd";
// import HotClick from "@web/ui/containers/HotClick";
import MarkdownTimeline from "./MarkdownTimeline";
import {FeatherIcon} from "@web/assets/icons";
// import {WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
// import ChatResponseToolResult from "./ChatResponseToolResult";
// import ChartManager from "@web/ui/containers/ChartManager";
// import {chatAppAgentIds} from "@oven/app-thescribe/src/dashboard.config";

// const AiAnswerWithCode = React.lazy(() => import("./AiAnswerWithCode"));

AiChatAnswer.propTypes = {
  agentId: PropTypes.string,
  answerIndex: PropTypes.number.isRequired,
  chatIndex: PropTypes.number,
  // path: PropTypes.string.isRequired,
  text: PropTypes.string,
  widget: PropTypes.object,
  toolCalls: PropTypes.array,
  toolResults: PropTypes.array,
  charts: PropTypes.object,
};

export default function AiChatAnswer(props) {
  // const [, width] = useContext(WindowContext);
  // console.log("AiChatAnswer", props);
  if (!props.text) {
    return null;
  }

  return <article className="sub-message" data-agentid={props.agentId}>
    {/*<AnswerActionHeader chatId={chatId} answer={answer} />*/}
    {/*<AiAgentHandle agentId={answer.agentId} />*/}
    {/*<section className="space space-end-y">*/}
    {/*  <div className="space space-col">*/}
        <Comment
          content={!!props.text ? <MarkdownTimeline
            markdown={props.text?.trim()}
            // path={props.path}
            active={false}
          /> : null
          }
          avatar={
          <Avatar
            icon={
              <FeatherIcon
                className="gold-icon"
                style={{position: 'relative', top: 4}}
              />
          }
            size="large"
            className="transparent-bg"
          />}
        />
      {/*</div>*/}
    {/*</section>*/}
    {/*<div className="space">*/}
    {/*  <div style={{maxWidth: width - 45}}>*/}
    {/*    {!!props.toolResults?.length &&*/}
    {/*      props.toolResults.map((toolResult) => {*/}
    {/*        return <div style={{marginTop: "10px"}} key={toolResult.id}>*/}
    {/*          {props.agentId !== "ai-analyst" &&*/}
    {/*            <div className="space space-col" data-tool={toolResult.id}>*/}
    {/*              <ChatResponseToolResult*/}
    {/*                path={props.path}*/}
    {/*                answerIndex={props.answerIndex}*/}
    {/*                toolResult={toolResult}*/}
    {/*                lazy={props.chatIndex > 0}*/}
    {/*              />*/}
    {/*            </div>}*/}
    {/*          {props.agentId === "ai-analyst" &&*/}
    {/*            <ChartManager*/}
    {/*              charts={props.charts}*/}
    {/*              answerIndex={props.answerIndex}*/}
    {/*              path={props.path}*/}
    {/*              lazy={false}*/}
    {/*            />}*/}
    {/*        </div>*/}
    {/*      })*/}
    {/*    }*/}
    {/*  </div>*/}
    {/*</div>*/}
  </article>
}
