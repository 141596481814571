import React, {useCallback} from "react";
import PropTypes from 'prop-types';
import {Button, Card, Tag, Comment} from "antd";
// import {updatePath} from "@web/lib/firestore.db";
// import now from "lodash/now";
import Day from "./Day";
// import {useDispatch, useSelector} from "react-redux";
import EmailAction from "./EmailAction";
// import {BusinessIcon, EmailIconOutlined, HeadphonesIcon, HomeOutlined, ShareIcon} from "@web/assets/icons";
// import appActions from "@web/redux/app/actions";
import VoiceCallButton from "./VoiceCallButton";
import UserAvatar from "./UserAvatar";
import ExternalLink from "./ExternalLink";

ContactInfoCard.propTypes = {
  path: PropTypes.string.isRequired,
  contact: PropTypes.object,
};

function ContactInfoCard(props) {
  // console.log("ContactInfoCard:props", props);
  // const dispatch = useDispatch();
  console.log("ContactInfoCard:props", props);
  const contact = props.contact; // useSelector((state) => state.Content.data[props.path]);
  // const setShowService =
  //   useCallback(
  //     (serviceId) => dispatch(appActions.setShowServices(true, serviceId)),
  //     [dispatch]);
  // const onSelect = (selectedUserId, e) => {
  //   console.log("ContactCard:onSelect", selectedUserId);
  //   e.preventDefault();
  //   e.stopPropagation();
  //   props.onSelect && props.onSelect(selectedUserId);
  //   const actionPath = props["action-path"];
  //   return actionPath &&
  //   updatePath(actionPath, {
  //     ["widget.response"]: {
  //       userId: selectedUserId,
  //       ts: now(),
  //     },
  //   }).then(() => false);
  // }
  // console.log("ContactCard:props", props);
  if (!contact) {
    return null;
  }

  // const getPhoneNumberIcon = (type) => {
  //   switch (type) {
  //     case "mobile":
  //       return <Tag>mobile</Tag>;
  //     case "home":
  //       return <;
  //     case "work":
  //       return <BusinessIcon />;
  //     default:
  //       return <HeadphonesIcon />;
  //   }
  // }


  return <Comment
      avatar={<UserAvatar
        // userId={props.path.split("/").pop()}
        name={contact.name}
        displayName={contact.name}
        src={contact.photoURL}
      />}
      author={
      <div className="space space-col">
        <div className="space space-center-y">
          <h6 className="capitalize">
            {contact.name}
          </h6>
          <span key="ts" className="muted font-sm">
              <Day ts={contact.activityTs} />
            </span>
        </div>
      </div>}
      // datetime={<Day ts={contact.activityTs} format={"MMM DD YYYY"} />}
      // actions={[<EmailAction key={contact.email} toEmails={[{email: contact.email, name: contact.name}]} action=""><EmailIconOutlined /></EmailAction>]}
      content={
        <div>
          <ExternalLink href={`https://${contact.domain}`} showIcon className="muted">{contact.companyName || contact?.domain}</ExternalLink>
          <EmailAction key={contact.email} toEmails={[{email: contact.email, name: contact.name}]} action="">
            <span className="muted">{contact.email}</span>
          </EmailAction>
          {contact.phoneNumbers?.map(({number, type}, index) => {
            return <div className="space space-center-y" key={number + index} style={{paddingTop: 3, paddingBottom: 5}}>
              <span key={number} className="muted">
                <Tag style={{width: 50}}>{type}</Tag> {number}
              </span>
              <VoiceCallButton
                to={number}
                name={contact.name}
                numberType={type}
                contactPath={props.path}
                size="small"
              />
            </div>;
          })}
        </div>
      }
    />
}

export default ContactInfoCard;
