import React, {useContext} from "react";
import {Card, Empty, List} from "antd";
import {WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import FileListItem from "./FileListItem";
import {DownArrowOutlined} from "@web/assets/icons";
import Loading from "./Loading";
import LineConnector from "./LineConnector";
import Pager from "@web/ui/containers/Pager";

FileList.propTypes = {
  list: PropTypes.array,
  grid: PropTypes.object,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

function FileList(props) {
  const [,, height] = useContext(WindowContext);

  return <div
    id="services-files-scroll-target"
    style={{height: height - 90, overflow: 'auto', display: 'flex', flexDirection: 'column', paddingLeft: 20}}
  >
    <Pager
      height={height - 90}
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-files-scroll-target"
    >
      <List
        header={
          <Card
            bordered={false}
            style={{width: "100%", margin: "0", height: 60}}
            // title={}
            // title={
            //   <ServiceData serviceId="reminders" component={RemindersList} />
            // <GroupTitle
            //   title="Activity"
            //   style={{paddingLeft: "20px"}}
            // >
            //   <div className="space space-col" style={{width: "100%"}}>
            //     <div style={{width: "90%", paddingTop: "100px"}}>
            //     {/*<ContentFilter />*/}
            //     </div>
            //     {/*<FeedSelector />*/}
            //     <ServiceData serviceId="reminders" component={RemindersList} />
            //   </div>
            // </GroupTitle>
            className="card-simple card-rounded card-no-header card-transparent card-no-separator card-slim-title"
          >
          </Card>
        }
        className="list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <FileListItem
            key={path}
            path={path}
            date={date}
          />
        }
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
          </Empty>
        }}
        grid
      />
    </Pager>
  </div>;
}

export default FileList;
