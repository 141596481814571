import React, {useContext} from 'react'
import {Button, Input} from "antd";
import HotClick from '@web/ui/containers/HotClick'
import {AppContext, WindowContext} from "@web/hooks/context";
import DictateButton from "./DictateButton";
import {SendArrow} from "@web/assets/icons";
import PropTypes from "prop-types";
// import VoiceCallButton from "./VoiceCallButton";
// import VoiceManagerButton from "./VoiceManagerButton";

const editorSize = {minRows: 1, maxRows: 10}

ChatInputTextarea.propTypes = {
  text: PropTypes.string,
  onSend: PropTypes.func,
  onTextChange: PropTypes.func,
  maxLength: PropTypes.number,
  showButton: PropTypes.bool,
  defaultValue: PropTypes.string,
  onFocus: PropTypes.func,
  actionLeft: PropTypes.node,
  dictate: PropTypes.bool,
  ai: PropTypes.bool,
  primary: PropTypes.bool,
  line: PropTypes.bool,
};

export default function ChatInputTextarea(props) {
  let editor
  const [, user, claims] = useContext(AppContext);
  const [isMobile] = React.useContext(WindowContext);
  const [isPending, startTransition] = React.useTransition();

  const onChange = (e) => {
    props.onTextChange(e.target.value);
    // startTransition(() => props.onTextChange(e.target.value))
  }

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    editor.focus();

    if (props.onPressEnter) {
      return props.onPressEnter(props.text);
    }

    props.onSend &&
    props.onSend(props.text);
    return false;
  }

  const onFocus = (e, isFocused) =>
    props.onFocus &&
    props.onFocus(editor, isFocused);

  const placeholder =
    props.placeholder || " Type here";

  const handleDictate = (transcript) => {
    transcript &&
    props.onTextChange(transcript);
  }

  const handleDictateProgress = (progress) => {
    console.log(progress);
  }

  const getTextAreaClassNames = () => {
    return isMobile ?
      `chat-editor-mobile${props.actionLeft ? " with-action-left" : ""}${props.primary ? " primary" : " not-primary"}${props.ai ? " ai" : " not-ai"}${props.line ? " line" : ""} ${props.className || ''}` :
      `brainstorm-editor${props.actionLeft ? " with-action-left" : ""}${props.primary ? " primary input-bold" : " not-primary"}${props.ai ? " ai" : " not-ai"}${props.line ? " line" : ""} ${props.className || ''}`
  }

  return <section
    style={{width: "100%", maxWidth: 1000}}
  >
    <div className={`relative${props.primary ? " primary" : ""}${isMobile ? " chat-editor-wrapper" : ""}`}>
      <div className={`chat-input-upload-button-wrapper ${isMobile ? "is-mobile" : "is-desktop"}`}>
        {props.actionLeft}
      </div>
      <div
        className="relative"
        style={{width: "100%"}}
      >
        <Input.TextArea
          // ghost
          bordered={false}
          ref={ref => (editor = ref)}
          placeholder={
          !user?.uid ?
            "Please log in" :
            placeholder
          }
          onChange={onChange}
          onFocus={(e) => onFocus(e, true)}
          onBlur={(e) => onFocus(e, false)}
          autoSize={props.editorSize || editorSize}
          defaultValue={props.defaultValue}
          value={props.text}
          maxLength={props.maxLength ?? 3000}
          className={getTextAreaClassNames()}
          size="large"
          disabled={!user?.uid || props.disabled}
          onPressEnter={onSubmit}
        />
        {!props.disabled &&
          props.dictate &&
          <span className={`apply-ant-btn ghost brainstorm-send-button icon-right${props.primary ? " primary" : ""}${isMobile ? " is-mobile" : " is-desktop"}`}>
            {/*<VoiceManagerButton />*/}
            <DictateButton
              onDictate={handleDictate}
              onProgress={handleDictateProgress}
              onClick={onFocus}
            />
          </span>}
          {!!props.showButton &&
            !props.disabled &&
            <HotClick>
              <Button
                className="brainstorm-send-button border-none"
                disabled={!user?.uid || !props.text}
                onClick={onSubmit}
                type="primary"
              >
                <SendArrow />
              </Button>
            </HotClick>
          }
      </div>
    </div>
  </section>
}
