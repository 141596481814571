import React from 'react';
import DashboardCenterRow from "./DashboardCenterRow";
// import HotlineIdentity from "./HotlineIdentity";
import GuidesManager from "./guides/GuidesManager";
import ServiceData from "@web/ui/containers/ServiceData";
import MessengerIdentity from "./MessengerIdentity";
import {Messenger} from "./guides/GuideCards";
import {setPath} from "@web/lib/firestore.db";
import now from "lodash/now";
import {api} from "@web/lib/api";
import {useLocation} from "wouter";

const endpoint = (process.env.NODE_ENV === "production") ?
  "https://line-v1-7zujc5azra-uw.a.run.app/messenger" :
  "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/messenger";

MessengerContent.propTypes = {}

function MessengerContent(props) {
  const [, setLocation] = useLocation();
  const steps = [
    [`onboarding/assistant`, Messenger],
  ];

  const onCompleteStep = React.useCallback((path) => {
    const stepName = path.split("/").pop();
    switch (stepName) {
      // case "terms":
      //   setPath(path, {completed: now(), seen: now(), date: now()}, true)
      //   .then(() => console.log("completed:", path));
      //   break;
      default:
        setPath(path, {seen: true, date: now()}, true)
        .then(() => console.log("completed:", path));
    }
  }, []);

  /**
   * @param {string} step
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   * @return {object}
   */
  const onUpdateStep = React.useCallback((step, updates) => {
    return api.POST(endpoint, {step, updates})
    .then((response) => {
      console.log("onUpdateStep", response);
      return response;
    })
    .catch((error) => error);
  }, []);

  const onExit = React.useCallback(() => {
    setLocation("/");
  }, []);

  return <div className={`chat-page-wrapper ${props.asOverlay ? "as-overlay" : "as-page"}`}>
    <DashboardCenterRow
      // left={<Card className="card-simple card-h-600 card-glass"></Card>}
      // right={<LinesManager />}
    >
      <section style={{width: 1000, margin: '0 auto'}}>
        {/*<MessengerIdentity />*/}
      </section>
      <ServiceData
        serviceId="messenger"
        component={GuidesManager}
        list={steps}
        onCompleteStep={onCompleteStep}
        onUpdateStep={onUpdateStep}
        onExit={onExit}
      />
    </DashboardCenterRow>
  </div>
}

export default MessengerContent;
