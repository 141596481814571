import React from "react";
import PropTypes from "prop-types";
import {PhoneCallIcon, XIcon} from "@web/assets/icons";
import voiceActions from "@web/redux/voice/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Tooltip} from "antd";
// import VoiceMuteButton from "./VoiceMuteButton";
// import VoiceRecordButton from "./VoiceRecordButton";
import {AppContext} from "@web/hooks/context";
// import AudioStreamManager from "./AudioStreamManager";

InCallControls.propTypes = {
  callSid: PropTypes.string.isRequired,
};

function InCallControls(props) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const voiceCall = useSelector((state) => state.Voice[props.callSid]);
  const acceptCall = (callSid) => dispatch(voiceActions.acceptCall(callSid));
  const disconnectCall = (callSid) => dispatch(voiceActions.disconnectCall(callSid));

  const onClickConnect = () => {
    console.log("onClickConnect", props.callSid, voiceCall);
    voiceCall.direction === 'INCOMING' && acceptCall(props.callSid);
  }

  /** Ignore controls for the user who initiated the call as it is redundant */
  if (voiceCall.member === user.uid) {
    return null;
  }

  const onClickCancel = () => {
    switch (voiceCall.status) {
      case 'pending':
      case 'ringing':
      case 'connecting':
      case 'open':
      case 'connected':
        return disconnectCall(props.callSid);
      default:
        return disconnectCall(props.callSid);
    }
  }

  const cannotCall = () => {
    switch (voiceCall.status) {
      case 'connecting':
      case 'connected':
      case 'disconnecting':
      // case 'pending':
      case 'open':
        return true;
      default:
        return false;
    }
  }

  const disableEndButton = () => {
    switch (true) {
      // case voiceCall.status === 'pending':
      case voiceCall.status === 'disconnected':
      case voiceCall.status === 'disconnecting':
        return true;
      default:
        return false;
    }
  }

  return <div className="space space-col" style={{width: '100%'}}>
    <div className="space space-center-y space-between fat-chip" style={{width: '100%'}}>
      <h6 className="bolded font-lg">{voiceCall.member}</h6>
      <div className="space space-center-y">
        <Tooltip key="connect-button" title="Answer" trigger={['hover']} placement="bottom">
          <Button
            ghost
            disabled={cannotCall()}
            onClick={onClickConnect}
            size="large"
            shape="circle"
          >
            <PhoneCallIcon
              className={cannotCall() ? '' : "green-icon"}
              style={{position: 'relative', top: '2px'}}
            />
          </Button>
        </Tooltip>
        <Tooltip key="end-button" title="Cancel" trigger={['hover']} placement="bottom">
          <Button
            danger
            ghost
            disabled={disableEndButton()}
            onClick={onClickCancel}
            size="large"
            shape="circle"
          >
            <XIcon size={26} />
          </Button>
        </Tooltip>
      </div>
    </div>
    {/*{voiceCall.member === "+18557836760" &&*/}
    {/*  <AudioStreamManager />}*/}
  </div>
}

export default InCallControls;
