import React from "react";
import {SpaceBarIcon} from "@web/assets/icons";
import {useSelector} from "react-redux";
import {AppContext} from "@web/hooks/context";

const VoiceShortcutsTips = () => {
  const [, user] = React.useContext(AppContext);
  const assistantPath = ["user", user.uid, "lines", "assistant"].join("/");
  const holdingSpacebar = useSelector((state) => state.App.holdingSpacebar);
  const assistantName = useSelector((state) => state.Lines.data[assistantPath]?.assistantName);
  const assistantVoice = useSelector((state) => state.Lines.data[assistantPath]?.voice?.name);

  if (!assistantName || !assistantVoice) return null;

  return <div className="space space-center-y space-center-x">
    <SpaceBarIcon size={40} className={`${holdingSpacebar ? "green-icon" : ""}`} />
    <h6 className={`${holdingSpacebar ? "green-color" : ""}`}><b>HOLD SPACEBAR</b> <span style={{color: 'rgba(247, 89, 171, 0.9025)'}}>TO CALL {assistantName.toUpperCase()}</span></h6>
  </div>;
};

export default VoiceShortcutsTips;
