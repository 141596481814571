import PropTypes from 'prop-types';
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);

Day.propTypes = {
  ts: PropTypes.number,
  format: PropTypes.string,
};

function Day(props) {
  const activityDay = dayjs(props.ts);

  if (activityDay.isToday() && !props.format) {
    return activityDay.format("h:mm a"); // "h:mma"
  }
  const format = props.format || "MMM D";
  return activityDay.year() < new Date().getFullYear() && format !== "YYYY" ?
    activityDay.format(format.replace("YYYY", "").trim()) :
    activityDay.format(format);
}

export default Day;
