import './App.less';
import React from "react";
import {Layout} from "antd";
import useWindowSize from "@web/hooks/useWindowSize";
import {WindowContext} from "@web/hooks/context";
import {isInStandaloneMode} from "@web/lib/app";
import Background from "@ai-antd/components/Background";
import DashboardHeader from "@ai-antd/components/DashboardHeader";
const {Content } = Layout;
const headerHeight = 64;

const FeedLayout = (props) => {
  const { width, height, isMobile } = useWindowSize();
  const isStandalone = isInStandaloneMode()
  const screen = [isMobile, width, height, headerHeight, isStandalone];
  return <div id="root-wrapper-scroll" style={{width, height: isMobile ? height + 10 : height, maxHeight: isMobile ? '101vh' : '100vh', overflow: 'auto'}}>
    <Background>
      <Layout className={props.className}>
        <div style={{position: "absolute", top: 0, left: 0, right: 0, zIndex: 1001}}>
          <DashboardHeader isMobile={isMobile}
            // right={
            //   <div style={{width: 400, padding: "0 22px 60px", position: "absolute", zIndex: 1001, top: "70px", right: 0}}>
            //     <LinesManager />
            //     {/*<NotificationsData component={AccountNotificationsList} />*/}
            //   </div>
            // }
          />
        </div>
        <Content className="layout-wrapper" style={{padding: 0, minHeight: height - headerHeight}}>
          <Layout style={{padding: "0", minHeight: height - headerHeight}}>
            <Content style={{padding: '0', minHeight: height - headerHeight, position: "relative"}}>
              <WindowContext.Provider value={screen}>
                {props.children}
              </WindowContext.Provider>
            </Content>
          </Layout>
        </Content>
      </Layout>
    </Background>
  </div>
};
export default FeedLayout;
