import React from "react";
import {StorageReference, FullMetadata} from "firebase/storage";
// import {ChatContext} from '@web/hooks/context'
import ChatMediaManager from "@ai-antd/components/ChatMediaManager";
import UploadButton from "@ai-antd/components/UploadButton";
import PropTypes from "prop-types";

/**
 * @typedef {Object} ChatRequestOptionsData
 * @property {string} [vs]
 */

ChatInputManager.propTypes = {
  pushPrompt: PropTypes.shape({
    text: PropTypes.string,
  }),
  onError: PropTypes.func,
  onSend: PropTypes.func,
  onTextChange: PropTypes.func,
  onMediaReady: PropTypes.func,
  children: PropTypes.element,
};

/**
 * ChatInputManager
 * @param {Object} props
 * @param {{text:string}|null} [props.pushPrompt]
 * @param {function(Object)} [props.onError]
 * @param {function(text:string)} [props.onSend]
 * @param {function(string)} [props.onTextChange]
 * @param {function(...filePath:string)} [props.onMediaReady]
 * @param {Object} props.children
 * @return {React.DetailedReactHTMLElement<{onMediaReady: onMediaReady, onError, onSend: ((function(*): Promise<*|void|undefined>)|*), text: unknown, media: *[], onTextChange: onTextChange}, HTMLElement>|*}
 */
export default function ChatInputManager(props) {
  const [text, setText] = React.useState(props.pushPrompt?.text || "");
  const [uploads, setUploads] = React.useState(null);
  const [links, setLinks] = React.useState([]);

  React.useEffect(() => {
    props.pushPrompt?.text &&
    setText(props.pushPrompt.text);
  }, [props.pushPrompt]);

  /**
   * Chat on-send handler
   * @function ChatInputManager.onSend
   * @return void
   */
  const onSend = () => {
    props.onSend &&
    props.onSend(text);
    setText("");
  }

  /**
   * onTextChange
   * @param {string} text
   * @return void
   */
  const onTextChange = (text) => {
    setText(text);
  }

  /**
   * onMediaUpload
   * @param {Object<string, {ref: StorageReference, metadata: FullMetadata, originFilename, percentComplete:number, state: string}>} uploadsToAdd
   * @return void
   */
  const onUploadChange = React.useCallback((mediaUploads) => {
    if (!Object.keys(mediaUploads)?.length) {
      return;
    }
    setUploads({
      ...uploads,
      ...mediaUploads,
    });
  }, [uploads]);

  /**
   * Send a no-text request to hydrate and preview url data
   * @param {string[]} links
   * @return void
   */
  const onLinkAdd = (links) => {
    setLinks(links);
  }

  return <React.Fragment>
    {!!uploads &&
      <ChatMediaManager
        uploads={uploads}
        onMediaReady={props.onMediaReady}
      />}
    {React.cloneElement(
      React.Children.only(props.children), {
        placeholder: props.placeholder,
        text,
        defaultValue: text,
        onSend,
        onTextChange,
        onLinkAdd,
        onFocus: props.onFocus,
        onError: props.onError,
        actionLeft:
          <UploadButton
            onChange={onUploadChange}
            ghost={false}
          />,
      })}
  </React.Fragment>;
}

