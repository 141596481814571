import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
import LineAppTasks from "./LineAppTasks";

function LineContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="progress" component={LineAppTasks} />
    </section>
  </DashboardFullRow>
}

export default LineContent;
