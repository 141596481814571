import React from "react";
import {AppContext} from "@web/hooks/context";
import VoiceCallButton from "./VoiceCallButton";
import {useSelector} from "react-redux";
import {Link} from "wouter";
import PropTypes from "prop-types";

Hotline.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

function Hotline(props) {
  const [, user, userClaims] = React.useContext(AppContext);
  const assistantPath = ["user", user.uid, "lines", "assistant"].join("/");
  const assistantName = useSelector((state) => state.Lines?.data?.[assistantPath]?.assistantName);
  if (!userClaims.data?._h) {
    return <span className="font-lg">
      <Link to="/claim" className="text-color bolded">Call assistant</Link>
    </span>;
  }
  return <div className="space space-center-y">
    <VoiceCallButton hideIcon size="small" to={userClaims.data?._h} text={props.text || `Call ${assistantName || "Assistant"}`} />
  </div>
}

export default Hotline;
