import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  modeClean: true,
  chatMode: false,
  optionsDrawer: {
    show: false,
  },
  activeServiceId: "contacts",
  activeDetailServiceId: "",
  activeSolutionId: "activity",
  showServices: true,
  activeDetail: null,
  videoModal: {},
  showNotifications: false,
  showLinesDrawer: false,
};

export default function reducer(state = initState, {type, solutionId, serviceId, props, show, showServices, activeDetail = null, showDetail, payload, hold}) {
  switch (type) {
    case actions.SHOW_OPTIONS_DRAWER:
      return {
        ...state,
        optionsDrawer: {
          ...state.optionsDrawer,
          show: true,
        },
      };
    case actions.HIDE_OPTIONS_DRAWER:
      return {
        ...state,
        optionsDrawer: {
          show: false,
        },
      };
    case actions.TOGGLE_MODE_CLEAN:
      return {
        ...state,
        modeClean: !state.modeClean,
      };
    case actions.ENTER_CHAT_MODE:
      console.log('ENTER_CHAT_MODE')
      return {
        ...state,
        chatMode: true,
      }
    case actions.EXIT_CHAT_MODE:
      console.log('EXIT_CHAT_MODE')
      return {
        ...state,
        chatMode: false,
      }
    case actions.TOGGLE_CHAT_MODE:
      console.log('TOGGLE_CHAT_MODE')
      return {
        ...state,
        chatMode: !state.chatMode,
      }
    case actions.SET_ACTIVE_SOLUTION_ID:
      return {
        ...state,
        activeSolutionId: solutionId,
        showServices: showServices ?? state.showServices,
      }
      case actions.SET_ACTIVE_SERVICE_ID:
        console.log('SET_ACTIVE_SERVICE_ID', serviceId, solutionId);
      return {
        ...state,
        activeServiceId: serviceId ?? state.activeServiceId,
        activeSolutionId: solutionId ?? state.activeSolutionId,
        showServices: showServices ?? state.showServices,
      }
    case actions.SET_ACTIVE_DETAIL: {
      console.log('SET_ACTIVE_DETAIL', activeDetail);
      return {
        ...state,
        solutionId,
        activeDetailServiceId: serviceId,
        [serviceId]: {
          props,
        },
        showDetail: true, // mobile only
      }
    }
    case actions.SET_SHOW_DETAIL: {
      console.log('SET_SHOW_DETAIL', showDetail);
      return {
        ...state,
        showDetail,
      }
    }
    case actions.CLEAR_ACTIVE_DETAIL: {
      return {
        ...state,
        activeDetailServiceId: "",
        // [serviceId]: null,
      }
    }
    case actions.OPEN_VIDEO_MODAL:
      return {
        ...state,
        videoModal: {
          open: true,
          url: payload.url,
        }
      }
    case actions.CLOSE_VIDEO_MODAL:
      return {
        ...state,
        videoModal: {
          open: false,
        }
      }
    case actions.SET_SPACEBAR_HOLD:
      return {
        ...state,
        holdingSpacebar: hold,
      }
      case actions.HIDE_NOTIFICATIONS:
      return {
        ...state,
        showNotifications: false,
      }
    case actions.SHOW_NOTIFICATIONS:
      return {
        ...state,
        showNotifications: true,
      }
    case actions.TOGGLE_LINES_DRAWER:
      return {
        ...state,
        showLinesDrawer: !state.showLinesDrawer,
      }
    case actions.TOGGLE_SHOW_SERVICES:
      return {
        ...state,
        showServices: !state.showServices,
      }
    default:
      return state;
  }
}
