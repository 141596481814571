import React from "react";
import {Button, Carousel, List} from "antd";
import backgroundActions from "@web/redux/background/actions";
import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch} from "react-redux";
import {UserTask} from "./GuideCards";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import appActions from "@web/redux/app/actions";
import ContentDrawerBackIcon from "../ContentDrawerBackIcon";
import WithDrawer from "@web/ui/containers/WithDrawer";
import ChatFeedback from "../ChatFeedback";
import WithCardVisibilityObserver from "../WithCardVisibilityObserver";
import ChatItem from "../ChatItem";

GuidesRouter.propTypes = {
  user: PropTypes.object.isRequired,
  list: PropTypes.array,
  initialStepIndex: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onCompleteStep: PropTypes.func,
  onUpdateStep: PropTypes.func,
  onExit: PropTypes.func,
  asList: PropTypes.bool,
  appPath: PropTypes.string,
};

export default function GuidesRouter(props) {
  const dispatch = useDispatch();
  const slider = React.useRef({});
  const [assistantData,,, snapshot] = useDocumentFirestore(["user", props.user.uid, "lines", "assistant"].join("/"));
  const [disableNavigation, setDisableNavigation] = React.useState(false);
  const startAnimation = React.useCallback(() => dispatch(backgroundActions.startAnimation()), [dispatch]);
  const pauseAnimation = React.useCallback(() => dispatch(backgroundActions.pauseAnimation()), [dispatch]);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(props.initialStepIndex);
  const [chat, setChat] = React.useState(null);
  const [chatAgentId, setChatAgentId] = React.useState(null);
  const [collectionPath, setCollectionPath] = React.useState("");

  const afterChange = React.useCallback((index) => {
    setActiveIndex(index);
  }, []);

  const navigateNext = React.useCallback(() => {
    slider.current?.next();
  }, []);

  const navigatePrev = React.useCallback(() => {
    slider.current?.prev();
  }, []);

  const markCompleteAndNavigateNext = React.useCallback((path) => {
    props.onCompleteStep && props.onCompleteStep(path);
    navigateNext();
  }, []);

  const onExit = React.useCallback(() => {
    props.onExit && props.onExit();
  }, []);

  const onBeforeChange = React.useCallback((currentIndex, nextIndex) => {
    (currentIndex < nextIndex) && markCompleteAndNavigateNext(props.list[currentIndex][1]);
    setChat(null);
  }, [props.list]);

  const setContext = React.useCallback((context) => {
    dispatch(appActions.setActiveDetail("lines", context, false));
  }, [dispatch]);

  const onShowDrawer = React.useCallback((show, {agentId, chat, collectionPath} = {}, from) => {
    setShowDrawer(show);
    setChatAgentId(agentId || "");
    setChat(chat || null);
    setCollectionPath(collectionPath || "");
  }, [setShowDrawer, setChatAgentId, setChat, setCollectionPath]);

  if (!props.user) return null;

  if (!props.list?.length) return null;

  if (!assistantData) return null;

  if (props.asList) {
    return <List
      bordered={false}
      dataSource={props.list}
      renderItem={([component, path, data], index) => {
        return <List.Item>
          <WithCardVisibilityObserver
            component={component}
            key={path}
            appPath={props.appPath}
            index={index}
            assistantPath={snapshot.ref.path}
            assistant={assistantData}
            path={path}
            user={props.user}
            isMobile={props.isMobile}
            width={props.width}
            height={props.height * 0.8}
            data={data}
            setContext={setContext}
            setDisableNavigation={setDisableNavigation}
            pauseAnimation={pauseAnimation}
            startAnimation={startAnimation}
            navigateNext={navigateNext}
            onExit={onExit}
            markCompleteAndNavigateNext={markCompleteAndNavigateNext}
            onUpdateStep={props.onUpdateStep}
            showDrawer={onShowDrawer}
          />
        </List.Item>
      }}
    />
  }

  return <section style={{position: 'relative'}}>
    <Carousel
      ref={(ref) => slider.current = ref}
      initialSlide={props.initialStepIndex}
      className="splash-carousel splash-carousel-dots"
      adaptiveHeight
      prevArrow={null}
      nextArrow={
      !props.isMobile &&
        !disableNavigation &&
        <Button icon={<RightArrowOutlined />} />
      }
      arrows={false}
      swipe={!disableNavigation}
      dots={!disableNavigation}
      accessibility={!props.isMobile && !disableNavigation}
      beforeChange={onBeforeChange}
      afterChange={afterChange}
      onSwipe={() => null}
      lazyLoad="ondemand"
      infinite={false}
      draggable={false}
      waitForAnimate={true}
      // dotPosition="left"
    >
      {props.list?.map?.(([component, path, data], index) => {
        return React.createElement(component, {
          key: path,
          appPath: props.appPath,
          index,
          isActive: index === activeIndex,
          assistantPath: snapshot.ref.path,
          assistant: assistantData,
          path: path,
          user: props.user,
          isMobile: props.isMobile,
          width: props.width,
          height: props.height,
          data,
          setContext,
          setDisableNavigation,
          pauseAnimation,
          startAnimation,
          navigateNext,
          navigatePrev,
          onExit: onExit,
          markCompleteAndNavigateNext,
          onUpdateStep: props.onUpdateStep,
          showDrawer: onShowDrawer,
        });
      })}
    </Carousel>
    <WithDrawer
      drawerKey="content-drawer"
      placement="right"
      height={props.height}
      width={props.width * 0.4}
      hideCloseIcon={true}
      onClose={() => setShowDrawer(false)}
      open={showDrawer}
      closeIcon={<ContentDrawerBackIcon />}
      maskClosable={true}
      ghost
      // header={<ContentFilter />}
      // footer={<LaunchSourceManagerButton
      //   key="sources-manager"
      //   serviceId={activeServiceId}
      // />}
    >
      <div className="space space-col center middle" style={{height: props.height, paddingLeft: 10, width: "86%"}}>
        <div className="chat-feedback-view" style={{minHeight: 400, maxHeight: props.height * 0.8, overflow: "auto", width: "100%"}}>
          <ul className="chat-thread-wrapper">
            {chat?
              <ChatItem path={""} chat={chat} chatIndex={0} hidePrompt /> :
              <ChatFeedback
                hidePrompt
                hideProgress
                agentId={chatAgentId}
                collectionPath={collectionPath}
              />
            }
          </ul>
        </div>
      </div>
    </WithDrawer>
  </section>
}
