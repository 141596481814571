import React, {useContext} from 'react';
// import {DetailContext, SourcesContext} from "@web/hooks/context";
import {useSelector} from "react-redux";
import {Card} from "antd";
import RecommendedTodo from "./RecommendedTodo";
import {api} from "@web/lib/api";
import MarkdownTimeline from "./MarkdownTimeline";
// import DashboardCenterRow from "./DashboardCenterRow";
// import {Card} from "antd";
// import LinesManager from "./LinesManager";

function ActiveDetailSummary() {
  // const {services} = useContext(SourcesContext);
  // const activeServiceId = useSelector((state) => state.App?.activeServiceId);
  const activeDetailServiceId = useSelector((state) => state.App?.activeDetailServiceId);
  const detailContent = useSelector(state => state.App[activeDetailServiceId]);
  const event = useSelector((state) => state.Content.events?.[detailContent?.props?.eventId]);
  const [dismissed, setDismissed] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setDismissed(true);
  }, []);

  React.useEffect(() => {
    // console.log("ActiveDetailSummary:event.activityPath:", event?.activityPath);
    event?.activityPath &&
    api.LINE(`/user/activity/seen`, {activityPath: event?.activityPath});
  }, [event?.activityPath]);

  return (detailContent?.props?.event?.summary || event?.recommendedTodo) &&
    <div className="space space-col">
      <Card title={<span className="muted">Summary</span>} className="card-simple card-rounded card-no-separator" bordered={false} style={{width: "100%"}}>
        <MarkdownTimeline markdown={detailContent?.props?.event?.summary} />
      </Card>
      <RecommendedTodo
        key={detailContent?.props?.eventId}
        eventId={detailContent?.props?.eventId}
        defaultExpanded={true}
        expand
        onDismiss={onDismiss}
        // expand={props.primary ? true : expanded}
        primary
      />
  </div>
}

export default ActiveDetailSummary;
