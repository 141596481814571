import {all, call, put, takeLeading, takeEvery, debounce} from "redux-saga/effects";
import actions from "./actions";
import {auth} from "@web/lib/firebase";
import {api} from "@web/lib/api";
const serviceUrl = () => {
  return (process.env.NODE_ENV === "production") ?
    "https://data-v1-timeline-7zujc5azra-uw.a.run.app" :
    "http://127.0.0.1:5001/admin-85663/us-west1/data-v1-timeline";
}
/**
 * @function makeActivityApiCall
 * @param {object} [params]
 * @return {Promise<web.client.ContextPayload|Error>}
 */
function request(params) {
  return api.POST(serviceUrl(), {...params || null})
    .then((response) => response.data)
    .catch((error) => {
      console.log("request:error:", error);
      return error;
  });
}

/**
 * Load Content
 * @return {Generator<*, void, *>}
 */
function* loadContent({params}) {
  console.log("loadContent:saga:", params.serviceId);
  if (!auth.currentUser?.uid) return;

  const response = yield call(request, params);

  if (response instanceof Error) {
    return yield put({
      type: actions.LOAD_CONTENT_ERROR,
      path: params.path,
      error: response.message,
    });
  }

  // console.log("loadContent:saga:", params, response);
  yield put({
    type: actions.LOAD_CONTENT_SUCCESS,
    ...response,
    params,
    serviceId: params.serviceId,
  });
}

/**
 * Load CONTEXT
 * @return {Generator<*, void, *>}
 */
function* loadContext({path, params = null}) {
  if (!auth.currentUser?.uid) {
    return;
  }
  /** @type {web.client.ContextPayload|Error} */
  const response =
    yield call(request, {path, ...params});

  if (response instanceof Error) {
    console.log("loadContext:error:", response);
    return yield put({
      type: actions.LOAD_CONTEXT_ERROR,
      error: response.message,
    });
  }
  console.log("loadContext:saga:", response);
  yield put({
    type: actions.LOAD_CONTEXT_SUCCESS,
    path,
    ...response,
    params,
  });
}

export default function* rootSaga() {
  yield all([
    takeLeading("LOAD_CONTENT_CONTACTS", loadContent),
    takeLeading("LOAD_CONTENT_REMINDERS", loadContent),
    takeLeading("LOAD_CONTENT_PROGRESS", loadContent),
    takeLeading("LOAD_CONTENT_MESSENGER", loadContent),
    takeLeading("LOAD_CONTENT_FILES", loadContent),
    takeLeading("LOAD_CONTENT_THREADS", loadContent),
    takeLeading("LOAD_CONTENT_PAYMENTS", loadContent),
    takeLeading("LOAD_CONTENT_DELIVERIES", loadContent),
    takeLeading(actions.LOAD_CONTEXT, loadContext),
    debounce(500, actions.FILTER_CONTENT, loadContent),
  ]);
}
