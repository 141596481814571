import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "antd";
import appActions from "@web/redux/app/actions";
import {WindowContext} from "@web/hooks/context";

// const defaultVideoUrl = "https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=1&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
const landscape = "https://player.vimeo.com/video/1050985582?h=52176db1d6&amp;badge=0&amp;autopause=1&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
const portrait = "https://player.vimeo.com/video/1050990781?h=2e3d8d65b1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";

function VideoModal() {
  const dispatch = useDispatch();
  const [isMobile, width, height] = React.useContext(WindowContext);
  const video = useSelector(state => state.App.videoModal);
  const onClose = () => dispatch(appActions.closeVideoModal());

  const vw = isMobile ? (width - 20) : 1280;
  const vh = isMobile ? (width * 0.5625) : 720;

  return isMobile ?
    !!video.open &&
    <div className="fullscreen">
      <iframe
        src={isMobile ? portrait : landscape}
        width={width}
        height={height}
        // frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        title="A brain for your business"
      ></iframe>
    </div> :
    !!video.open &&
    <Modal destroyOnClose title="GetLine AI" open={video.open} onOk={onClose} onCancel={onClose} width={isMobile ? width : (vw + 40)} footer={null} bodyStyle={isMobile ? {paddingLeft: 0} : {}}>
      {/*<iframe src="https://player.vimeo.com/video/874261241?h=4556923902" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>*/}
      <div style={{width: vw, height: vh}}>
        {!!video.open && <iframe
          src={isMobile ? portrait : landscape}
          width={vw}
          height={vh}
          // frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title="A brain for your business"
        ></iframe>}
      </div>
    </Modal>
}

export default VideoModal;
