// import {AppContext, LinesContext} from "@web/hooks/context";
import ServiceSelector from "./ServiceSelector";
import PropTypes from "prop-types";
// import {useSelector} from "react-redux";
// import {ActivityIcon, CPUIcon, GlobeIcon} from "@web/assets/icons";

Selector.propTypes = {
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

function Selector(props) {
  return <div className="space space-center-y">
    <ServiceSelector
      onClick={props.onChange}
      items={props.items}
      text={props.items?.find?.(({value}) => value === props.value)?.label || props.placeholder}
    />
  </div>
}

export default Selector;
