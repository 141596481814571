import React, {useContext} from "react";
import {AppContext} from "@web/hooks/context";
import {Redirect} from "wouter";

export const RequireAuth = ({allowAnonymous, children}) => {
  const [, user] = useContext(AppContext);

  if (!user) {
    return <Redirect to="/login" redirect={location.pathname} />;
  }
  if (!allowAnonymous && user.isAnonymous) {
    return <Redirect to="/login" redirect={location.pathname} />;
  }

  return children;
};
