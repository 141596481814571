import React from 'react';
import PropTypes from 'prop-types';
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import ChatWaitForWidget from "@ai-antd/components/ChatWaitForWidget";
import {RightArrowOutlined} from "@web/assets/icons";
import {Card, Divider, Tag, Tooltip} from "antd";
import ChatResponseToolResult from "@ai-antd/components/ChatResponseToolResult";
import ChartManager from "./ChartManager";
import Day from "@ai-antd/components/Day";

ChatProgressData.propTypes = {
  path: PropTypes.string.isRequired,
};

function ChatProgressData(props) {
  const [chat] = useDocumentFirestore(props.path);

  if (!chat) {
    return null;
  }

  // console.log("ChatProgressData:chatPath:answers:", props.path, chat.answers);

  return <React.Fragment>
    {!!chat.answers?.length &&
      chat.answers.map((answer) =>
        !answer.toolCalls &&
        !!answer.toolResults?.length &&
        answer.toolResults.map((toolResult) => {
          return <div
            style={{marginTop: "10px"}}
            key={toolResult.id}
          >
            {answer.agentId !== "ai-analyst" &&
              <div
                className="space space-col"
                data-tool={toolResult.id}
              >
                <ChatResponseToolResult
                  path={props.path}
                  answerIndex={props.answerIndex}
                  toolResult={toolResult}
                  lazy
                />
              </div>}
            {answer.agentId === "ai-analyst" &&
              <ChartManager
                charts={props.charts}
                answerIndex={props.answerIndex}
                path={props.path}
                lazy={false}
              />}
          </div>
        }))
    }
    {chat.widget &&
      <ChatWaitForWidget
        widget={chat.widget}
        path={props.path}
      />
    }
    {chat.progress &&
      <React.Fragment>
        <Divider className="small-top small-bottom" />
        <Tooltip
          trigger={["click"]}
          title={
          <Card className="card-simple card-rounded" bordered={false} title="Technical Reasoning">
            <div className="space space-col">
              {chat.progressLog?.map?.((log) => log.split("|"))
              .sort((a, b) => a[0] - b[0])
              .map(([timestamp, ...rest], index) => {
                // const [timestamp, ...rest] = log.split("|");
                return <div key={index} className="space space-center-y">
                  <div className="font-thin muted" style={{minWidth: 80}}><Day ts={timestamp} format={"hh:mm a"}/></div>
                  <span className="font-thin">{rest.map((str, index) => <Tag key={str} color={index? "blue" : "green"}>{str}</Tag>)}</span>
                </div>;
              })}
            </div>
          </Card>}
          placement="topLeft"
          destroyTooltipOnHide
        >
          <div className="space space-center-y">
            <RightArrowOutlined
              className="gold-icon"
              style={{minWidth: "20px"}}
              size={24}
            />
            <span className="ai-text font-lg">
              {chat.progress?.split?.("|").pop()}
            </span>
          </div>
        </Tooltip>
      </React.Fragment>
    }
  </React.Fragment>;
}

export default ChatProgressData;
