import React from "react";
import PropTypes from "prop-types";

NavigationLabel.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.string,
};

function NavigationLabel(props) {
  return <div className={props.active ? "underline" : ""} style={{textUnderlineOffset: "8px", textDecorationColor: "#fff"}}>
    {props.text}
  </div>
}

export default NavigationLabel;
