import React, {useCallback} from "react";
import {List, Tag, Typography} from "antd";
// import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
// import ExternalLink from "./ExternalLink";
import useDomainIcon from "@web/hooks/useDomainIcon";
import ListItemActive from "./ListItemActive";
import ActivityListItem from "./ActivityListItem";
import ActivityData from "@web/ui/containers/ActivityData";
import now from "lodash/now";
import {FlagIcon, TodoIcon, XIcon} from "@web/assets/icons";
import ActivityIconStrip from "./ActivityIconStrip";

function ContactsListItem(props) {
  const dispatch = useDispatch();
  const isMobile = true;
  const contact = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });
  const isActive = useSelector((state) => {
    return state.App[state.App.activeDetailServiceId]?.props?.path === props.path;
  });
  const eventId = useSelector((state) => {
    // console.log("ContactsListItem:eventId", props.itemPath, props.path);
    return state.Content.activity?.[props.path]?.eventsByItem?.[props.itemPath]?.[0];
  });
  const event = useSelector((state) => state.Content.events[eventId]);
  const isDismissed = event?.recommendedTodo?.status === "dismissed";
  // console.log("ContactsListItem:eventId",  eventId, event);
  // const item = useSelector((state) => {
  //   console.log("ContactsListItem:item", state.Content.activity?.[props.path]);
  //   return state.Content.activity?.[props.path]?.itemsList?.[0];
  // });

  const DomainIcon = useDomainIcon(contact?.domain, contact?.indicators);
  const onClick = useCallback((e) => {
    const data = {
      path: props.path, contact, event, eventId,
    };

    if (props.onClick) {
      return props.onClick(data, e);
    }
    dispatch(appActions.setActiveDetail("contacts", data, true));
  }, [dispatch,props]);

  if (!contact) {
    return null
  }

  const displayName = contact.name?.substring(0, 20).replaceAll('"', '') || contact.email;

  return isActive ?
    // <ActivityData key={props.path} path={props.path}>
    <ActivityListItem
      contextPath={props.path}
      itemPath={props.itemPath}
      primary={false}
    /> :
    // </ActivityData> :
    <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[]}
    onClick={onClick}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={
            isDismissed ?
            <XIcon className="muted-icon" /> :
          event?.isUrgent ?
            <FlagIcon
            key="urgent"
            style={(event?.activityTs > (now() - (1000 * 60 * 60 * 48))) ? {color: "orange"} : null}
            size={18}
          /> :
            event?.recommendedTodo ?
              <TodoIcon key="todo" style={{color: "white"}} /> :
              DomainIcon
          }
          userId={props.path.split("/").pop()}
          name={contact.name}
          displayName={displayName}
          src={contact.photoURL}
        />}
      title={
        <div className="space space-center-y">
          <h6 className={isDismissed ? "muted capitalize" : "capitalize"}>
            {displayName}
          </h6>
          {/*{!!eventId && <ActivityIconStrip eventId={eventId} />}*/}
          <span key="ts" className="muted font-sm">
            <Day ts={contact.activityTs}/>
          </span>
        </div>}
      description={
        isDismissed ?
          <label className="muted line-clamp-1">{event?.title || ""}</label> :
          <div className="space lowercases" style={{width: "80%"}}>
            <span className="muted line-clamp-1">{event?.title || ""}</span>
            {/*<div>*/}
            {/*  {event?.labels?.map((label, index) => {*/}
            {/*  if (index < 2) {*/}
            {/*    return <Tag className="muted" key={displayName}>{label}</Tag>;*/}
            {/*  }*/}
            {/*  return null;*/}
            {/*})}*/}
            {/*</div>*/}
          </div>}
    />
      <ListItemActive
        path={props.path}
        offset={"5px"}
  />
  </List.Item>
}

export default ContactsListItem;
