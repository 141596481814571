import React from 'react';
// import DashboardCenterRow from "./DashboardCenterRow";
// import HotlineIdentity from "./HotlineIdentity";
import GuidesManager from "./guides/GuidesManager";
import {AppContext, LinesContext} from "@web/hooks/context";
import {buildQuery, Fields, setPath} from "@web/lib/firestore.db";
import now from "lodash/now";
// import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {useLocation} from "wouter";
// import AudioStreamData from "@web/ui/containers/AudioStreamData";
// import AudioStreamManager from "./AudioStreamManager";
import {api} from "@web/lib/api";
import {
  AppAddService,
  // AppDataSourceCrawl,
  // AppDataSourceSearch,
  // AppDataSourceUrl,
  // AppSummary,
  // AppTraining,
  AppServiceContainer,
} from "./guides/AppCards";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {Card} from "antd";
import {useDispatch, useSelector} from "react-redux";
import appsActions from "@web/redux/apps/actions";
// import DashboardFullRow from "./DashboardFullRow";
// import ChatPage from "@ai-antd/page/ChatPage";
// import {notifyAndTrack} from "./notification";
// import VoiceShortcutsTips from "./VoiceShortcutsTips";

const endpoint = (process.env.NODE_ENV === "production") ?
  "https://line-v1-7zujc5azra-uw.a.run.app/apps" :
  "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/apps";

LineAppsCustom.propTypes = {}

function LineAppsCustom(props) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const linesContext = React.useContext(LinesContext);
  const [, setLocation] = useLocation();
  const appPath = ["user", user.uid, "apps", linesContext.appId].join("/");
  const userApp = useSelector((state) => {
    // console.log("state.Apps.data", state.Apps.data);
    return state.Apps.data?.[appPath];
  });
  // console.log("userApp", {userApp, linesContext});
  const onCompleteStep = React.useCallback((path) => {
    const stepName = path.split("/").pop();
    switch (stepName) {
      // case "terms":
      //   setPath(path, {completed: now(), seen: now(), date: now()}, true)
      //   .then(() => console.log("completed:", path));
      //   break;
      default:
        setPath(path, {seen: true, date: now()}, true)
        .then(() => console.log("completed:", path));
    }
  }, []);

  /**
   * @param {string} servicePath
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   * @return {object}
   */
  const onUpdateStep = React.useCallback((servicePath, updates) => {
    return api.POST(`${endpoint}/${linesContext.appId}`, {servicePath, updates})
    .then((response) => {
      console.log("onUpdateStep", response);
      return response;
    })
    .catch((error) => error);
  }, []);

  const onExit = React.useCallback(() => {
    setLocation("/");
  }, []);

  React.useEffect(() => {
    console.log("loadUserAppServices", {linesContext, userApp});
    userApp &&
    dispatch(appsActions.loadUserAppServices(appPath));
  }, [appPath, userApp]);

  if (!userApp) {
    return <Card className="card-rounded" bordered={false} style={{width: 360, margin: "100px auto"}}>
      <span className="font-lg">App not found</span>
    </Card>;
  }

  const serviceSteps = userApp?.servicesPaths?.map?.((path) => {
    return [AppServiceContainer, path];
  });

  return <GuidesManager
    list={[...serviceSteps || [], [AppAddService, appPath, userApp]].filter(Boolean)}
    appPath={appPath}
    onCompleteStep={onCompleteStep}
    onUpdateStep={onUpdateStep}
    onExit={onExit}
  />
}

export default LineAppsCustom;
