import React from "react";
import {Button} from "antd";
import {AddItemIcon, EmailIconOutlined, LeftArrowOutlined, PlusCircle} from "@web/assets/icons";
import appActions from "@web/redux/app/actions";
import {useDispatch} from "react-redux";

function LaunchSourceManagerButton(props) {
  const dispatch = useDispatch();
  const showOptionsDrawer = React.useCallback((serviceId) =>
    dispatch(appActions.showOptionsDrawer()), [dispatch]);

  // return <Button
  //   ghost className="icon-lefts icon-btn"
  //   onClick={showOptionsDrawer}
  //   size={"large"}
  //   style={{position: 'relative', bottom: '-1px'}}
  // >
  //   <PlusCircle className="menu-icon" />
  // </Button>;
  return <label className="cursor-pointer" onClick={showOptionsDrawer}>{props.children}</label>;
}

export default LaunchSourceManagerButton;
