import React from "react";
import {Avatar, Tag, Typography} from "antd";
import ContentActions from "./ContentActions";
import {AppContext, WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import Day from "./Day";
import {DownArrowOutlined, DownFromLineIcon, DownIcon, UpChevron} from "@web/assets/icons";

ChatPrompt.propTypes = {
  path: PropTypes.string.isRequired,
  chat: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  collapsed: PropTypes.bool,
};

export default function ChatPrompt(props) {
  // console.log("ChatPrompt:props:", props);
  if (!props.chat?.prompt?.text) {
    return null;
  }

  return <ContentActions
    path={props.path}
    content={props.chat?.prompt}
    like={props.chat?.like}
    trigger="contextMenu"
  >
    <li
      className="brainstorm-message right"
      onClick={props.onClick}
      style={{marginBottom: "0"}}
    >
      <div className="space space-center-y gap-30">
        {/*<Avatar src={user.photoURL} size="large" className="chat-avatar"/>*/}
        <div
          className="fat-chip primary font-lg transparent-bg"
          style={{padding: '13px 42px 13px 30px'}}
          key={props.path}
        >
          <div className={`space space-center-y ${props.primary ? 'primary' : 'muted'}`}>
            {props.collapsed && <DownArrowOutlined />}
            {props.chat?.prompt?.text}
              <span
                className="muted font-sm relative"
                style={{display: 'inline-block'}}
              >
                <Day ts={props.chat?.prompt?.createdTs} />
              </span>
          </div>
        </div>
      </div>
    </li>
  </ContentActions>
}
