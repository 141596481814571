import React from 'react';
import {useSelector} from "react-redux";
import {BackArrowIcon, LeftArrowOutlined, RightArrowOutlined, XIcon} from "@web/assets/icons";

function ContentDrawerBackIcon(props) {
  const showFilter = useSelector((state) => !!state.Content.showFilter);
  const showServices = useSelector((state) => state.App.showServices);

  return showFilter ?
    <XIcon className="green-icon" /> :
    showServices ?
      <LeftArrowOutlined className="green-icon hidden" /> :
      <RightArrowOutlined className="green-icon hidden" />;
}

export default ContentDrawerBackIcon;
