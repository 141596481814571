import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
// import {
//   getAllItemPaths,
//   getSelectedItemPaths,
//   getSelectedMemberItemPaths,
//   getSelectedUserId
// } from "@web/redux/activity/selectors";
import {AppContext} from "@web/hooks/context";
import appActions from "@web/redux/app/actions";
import contentActions from "@web/redux/content/actions";
// import Progress from "@ai-antd/page/Progress";

ActivityData.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
};

function ActivityData(props) {
  // const itemPaths = useSelector(getSelectedItemPaths);
  // const allItemPaths = []; // !selectedUserId && useSelector(getAllItemPaths);
  //
  // return React.cloneElement(
  //   React.Children.only(props.children),
  //   {itemPaths: itemPaths || allItemPaths});
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);

  const clearActiveDetail = React.useCallback(() => {
    dispatch(appActions.clearActiveDetail());
  }, []);

  const loadAllContent = React.useCallback(() => {
    dispatch(contentActions.loadAllContent({path: props.path}));
  }, [props.path]);

  const contact = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  // const activityList = useSelector((state) =>
  //   state.Content.activity?.[props.path]?.activityList);

  const itemsList = useSelector((state) =>
    state.Content.activity?.[props.path]?.itemsList);

  // useEffect(() => {
  //   path && dispatch(contentActions.loadContent({path}));
  // }, [path]);

  if (!user?.uid) {
    return null;
  }
  if (!props.path) return null;
  if (!itemsList?.length) return null;

  // if (!props.path) {
  //   return <Progress />;
  // }
  // console.log("ActivityData:props", props);

  return React.cloneElement(
    React.Children.only(props.children), {
      ...props,
      contextPath: props.path,
      // activityList,
      itemPath: itemsList?.[0]?.[0],
      contact: contact || props.contact,
      onClose: clearActiveDetail,
      loadAllContent,
    });
}

export default ActivityData;
