import { combineReducers } from "redux";
import App from "./app/reducers";
import Apps from "./apps/reducers";
// import Events from "./events/reducers";
import Calendar from "./calendar/reducers";
// import Search from "./search/reducers";
import Tasks from "./apps/reducers";
import Activity from "./activity/reducers";
// import Documents from "./documents/reducers";
import Background from "./background/reducers";
// import Users from "./users/reducers";
import Voice from "./voice/reducers";
// import Timeline from "./timeline/reducers";
import Content from "./content/reducers";
import AI from "./ai/reducers";
import Lines from "./lines/reducers";

/**
 * @typedef {object} app.State
 * @memberof app
 * @property {State.Activity} Activity
 * @property {State.Content} Content
 * @property {State.App} App
 * @property {State.Email} Email
 * @property {State.Calendar} Calendar
 * @property {State.Events} Events
 * @property {State.Search} Search
 * @property {State.Tasks} Tasks
 * @property {State.Documents} Documents
 * @property {State.Background} Background
 * @property {State.Users} Users
 * @property {State.Voice} Voice
 * @property {State.Timeline} Timeline
 * @property {State.AI} AI
 * @property {State.Apps} Apps
 */
const collections = {
  App,
  Lines,
  Apps,
  // Events,
  Calendar,
  // Search,
  Tasks,
  Activity,
  Content,
  // Documents,
  Background,
  // Users,
  Voice,
  // Timeline,
  AI,
};
export default combineReducers(collections);
