import React from "react";
import PropTypes from "prop-types";
import OauthConnectButton from "./OauthConnectButton";
import {List} from "antd";

AppIntegrationListItem.propTypes = {
  integrationId: PropTypes.string.isRequired,
  integration: PropTypes.object.isRequired,
  iconStyle: PropTypes.object,
};

function AppIntegrationListItem({integrationId, integration, iconStyle}) {
  if (!integration) return null;
  const {name, description, website, provider} = integration;
  return <List.Item
    key={integrationId}
    actions={[
      <div className="space space-center-y">
        <OauthConnectButton
          integrationId={integrationId}
          integration={integration}
        />
        {/*<Tag className="muted rounded">REASONING</Tag>*/}
        {/*<Button size="large" ghost className="hidden"><Icon name="RightArrowOutlined"/></Button>*/}
      </div>
    ]}
  >
    <div className="space space-center-y"><span className="font-xl boldeds">{name}</span><span className="muted"></span></div>
    {/*<List.Item.Meta title={<a href={website?.url || "#"} target="_blank"><h6>{name}</h6></a>} />*/}
    {/*<div>*/}
    {/*  <OauthConnectButton*/}
    {/*    integrationId={integrationId}*/}
    {/*    integration={integration}*/}
    {/*  />*/}
    {/*</div>*/}
  </List.Item>;
}

export default AppIntegrationListItem;
