/** @namespace app */
import "./App.less";
import React, {lazy, Suspense} from "react";
import FeedLayout from "./FeedLayout";
import {Route, Switch, useLocation} from "wouter";
import Login from "@ai-antd/page/Login";
import SignUp from "@ai-antd/page/SignUp";
import {useAuthOven} from "@web/hooks/useAuthOven";
import {auth} from "@web/lib/firebase";
import {AppContext, LinesContext, SourcesContext, PayContext} from "@web/hooks/context";
import Loading from "@ai-antd/components/Loading";
import {RequireAuth} from "@web/ui/containers/RequireAuth";
import OauthCallback from "@ai-antd/page/OauthCallback";
import VerifyEmailLink from "@ai-antd/page/VerifyEmailLink";
import {Alert} from "antd";
import TrelloAuthCallback from "@ai-antd/components/TrelloAuthCallback";
import VideoModal from "@ai-antd/components/VideoModal";
// import Logout from "@ai-antd/page/Logout";
import Home from "@ai-antd/page/Home";
// import RequireVerification from "@web/ui/containers/RequireVerification";
// import OnboardingRouter from "@ai-antd/components/onboarding/OnboardingRouter";
// import ProOnboarding from "@ai-antd/components/onboarding/ProOnboarding";
import PhoneAuth from "@ai-antd/components/PhoneAuth";
import {getServices, getSolutions} from "./dashboard.config";
import AuthLayoutWrapper from "@ai-antd/components/AuthLayoutWrapper";
import {useDispatch} from "react-redux";
import WildcardRoute from "@ai-antd/components/WildcardRoute";
// import ContentViewerPage from "@ai-antd/components/ContentViewerPage";
// import VoiceCallManager from "@ai-antd/components/VoiceCallManager";
// import WithDrawer from "@web/ui/containers/WithDrawer";
// import VoiceDrawer from "@ai-antd/components/VoiceDrawer";
// import AppsPage from "@ai-antd/page/AppsPage";
// import {CheckoutProvider} from "@stripe/react-stripe-js";
// import Pay from "@ai-antd/page/Pay";
// import Paid from "@ai-antd/components/Paid";
// import ProOnboarding from "@ai-antd/components/onboarding/ProOnboarding";

const appKey = process.env.REACT_APP_OVEN_APP_ID;
const configKey = appKey?.replace(/-/g, "_");
const About = lazy(() => import("@ai-antd/page/About"));
const Account = lazy(() => import("@ai-antd/page/Account"));
const LinePage = lazy(() => import("@ai-antd/page/LinePage"));
const MessengerPage = lazy(() => import("@ai-antd/page/MessengerPage"));
const AppsPage = lazy(() => import("@ai-antd/page/AppsPage"));
const LineOnboarding = lazy(() => import("@ai-antd/page/LineOnboarding"));
const ProPage = lazy(() => import("@ai-antd/page/ProPage"));
const Splash = lazy(() => import("@ai-antd/components/Splash"));
const AuthEmailPage = lazy(() => import("@ai-antd/page/EmailAuthPage"));
const ProOnboarding = lazy(() => import("@ai-antd/components/onboarding/ProOnboarding"));
const Pay = lazy(() => import("@ai-antd/page/Pay"));
const Paid = lazy(() => import("@ai-antd/components/Paid"));
const Logout = lazy(() => import("@ai-antd/page/Logout"));
const ContentViewerPage = lazy(() => import("@ai-antd/components/ContentViewerPage"));

const solutions = getSolutions();
const services = getServices();

const App = () => {
  /** @type {useAuthOvenData} */
  const [
    user,
    claims,
    loadingUser,
    errorLoadingUser,
  ] = useAuthOven(auth);

  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const navigateTo = React.useCallback((path) => setLocation(path), [setLocation]);

  React.useEffect(() => {
    console.log("sync lines");
    user && !user.isAnonymous &&
    dispatch({type: "SYNC_LINES"});
  }, [dispatch, user]);

  if (loadingUser) return <Loading/>;

  if (errorLoadingUser) {
    return <Alert type="warning" banner message="Please refresh or try again later" description="TheScribe is currently unavailable." />;
  }

  if (!claims?.data) return <Loading/>;

  const appContextData = [{root: {key: appKey, configKey}, navigateTo}, user, claims, []];

  return <AppContext.Provider value={appContextData}>
    <SourcesContext.Provider value={{services, solutions}}>
    <FeedLayout>
      <Switch>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/claim">
          <RequireAuth allowAnonymous>
            <Suspense fallback={<Loading/>}>
              <Splash />
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/start">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <LineOnboarding />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/line">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                {/*<ProPage />*/}
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/onboarding">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <ProOnboarding />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/pro" key="pro">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <ProPage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        {/*<Route path="/chat-demo" key="logout">*/}
        {/*  <Suspense fallback={<Loading />}>*/}
        {/*    <ChatDemoManager>*/}
        {/*      <ChatDemo />*/}
        {/*    </ChatDemoManager>*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        <Route path="/logout" key="logout">
          <Suspense fallback={<Loading/>}>
            <Logout/>
          </Suspense>
        </Route>
        {/*<Route path="/genesis">*/}
        {/*  <Suspense fallback={<Loading/>}>*/}
        {/*    <Splash />*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        {/*<Route path="/verify">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <Splash initialSlide="signup"/>*/}
        {/*    </Suspense>*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        <Route path="/subscribe">
          <RequireAuth allowAnonymous={false}>
            {/*<RequireVerification>*/}
              <Suspense fallback={<Loading/>}>
                <Splash initialSlideKey="subscribe" />
              </Suspense>
            {/*</RequireVerification>*/}
          </RequireAuth>
        </Route>
        <Route path="/pay/:priceId?">
          {(params) => <PayContext.Provider value={[params.priceId]}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <Pay />
              </Suspense>
            </RequireAuth>
          </PayContext.Provider>}
        </Route>
        <Route path="/paid">
          <RequireAuth allowAnonymous={true}>
            {/*<RequireVerification>*/}
            <Suspense fallback={<Loading/>}>
              <Paid />
            </Suspense>
            {/*</RequireVerification>*/}
          </RequireAuth>
        </Route>
        {/*<Route path="/subscribed" key="subscribed">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    /!*<RequireVerification>*!/*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <BetaCountdown/>*/}
        {/*    </Suspense>*/}
        {/*    /!*</RequireVerification>*!/*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        {/*<Route path="/genesis-access">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <MemberBenefits/>*/}
        {/*    </Suspense>*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        {/*<Route path="/genesis-start">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <Splash initialSlideKey="subscribe" />*/}
        {/*    </Suspense>*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        <Route path="/email-link">
          <VerifyEmailLink/>
        </Route>
        <Route path="/verify-email">
          <AuthLayoutWrapper hideLinks>
            <AuthEmailPage intent="link" />
          </AuthLayoutWrapper>
        </Route>
        <Route path="/phone/:intent">
          {({intent}) => <AuthLayoutWrapper hideLinks>
            <PhoneAuth intent={intent} />
          </AuthLayoutWrapper>
          }
        </Route>
        <Route path="/oauth/:integrationId/callback" key="oauth-callback">
          {({integrationId}) => integrationId === "trello-com" ? <TrelloAuthCallback integrationId={integrationId}/> :
            <OauthCallback integrationId={integrationId}/>}
        </Route>
        <Route path="/login" key="login">
          <Login providers={["email"]} />
        </Route>
        <Route path="/sign-up" key="sign-up">
          <SignUp providers={["email"]}/>
        </Route>
        <Route path="/account" key="account">
          <Suspense fallback={<Loading/>}>
            <Account/>
          </Suspense>
        </Route>
        {/*<Route path="/get-started" key="get-started">*/}
        {/*  <Suspense fallback={<Loading/>}>*/}
        {/*    <Account onboarding/>*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        <Route path="/about" key="about">
          <Suspense fallback={<Loading/>}>
            <About/>
          </Suspense>
        </Route>
        <Route path="/:hotline/apps/:appId">
          {(params) => <LinesContext.Provider value={{...params, serviceId: "apps", solutionId: "hotline"}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading />}>
                <AppsPage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>}
        </Route>
        <Route path="/links">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading />}>
              <ContentViewerPage />
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/:hotline">
          {(params) => <WildcardRoute param={params.hotline}>
            <LinesContext.Provider value={{...params, serviceId: "messenger", solutionId: "hotline"}}>
              <RequireAuth allowAnonymous={false}>
                <Suspense fallback={<Loading />}>
                  <MessengerPage />
                </Suspense>
              </RequireAuth>
            </LinesContext.Provider>
          </WildcardRoute>}
        </Route>
        {/*<Route path="/io">*/}
        {/*  <Suspense fallback={<Loading />}>*/}
        {/*    <Io />*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
      </Switch>
      <VideoModal/>
      {/*<VoiceDrawer />*/}
    </FeedLayout>
    </SourcesContext.Provider>
  </AppContext.Provider>;
};
export default App;
