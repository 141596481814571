const actions = {
  START_ANIMATION: "START_ANIMATION",
  STOP_ANIMATION: "STOP_ANIMATION",
  PAUSE_ANIMATION: "PAUSE_ANIMATION",
  SET_OPACITY: "SET_OPACITY",
  TOGGLE_MUTE: "TOGGLE_MUTE",
  START_CONTENT_VIDEO: "START_CONTENT_VIDEO",
  END_CONTENT_VIDEO: "END_CONTENT_VIDEO",
  RESET_VIDEO_STATUS: "RESET_VIDEO_STATUS",
  END_CONTENT_VIDEO_SEQUENCE: "END_CONTENT_VIDEO_SEQUENCE",

  startAnimation: () => {
    // console.log('startAnimation1')
    return {
      type: actions.START_ANIMATION,
    }
  },
  stopAnimation: () => {
    // console.log('stopAnimation1')
    return {
      type: actions.STOP_ANIMATION,
    }
  },
  pauseAnimation: () => {
    // console.log('pauseAnimation1')
    return {
      type: actions.PAUSE_ANIMATION,
    };
  },
  setOpacity: (opacity = 1) => {
    console.log('setOpacity1')
    return {
      type: actions.SET_OPACITY,
      opacity,
    };
  },
  toggleMute: () => {
    return {
      type: actions.TOGGLE_MUTE,
    };
  },
  /**
   * @function startContentVideo
   * @param {object} options
   * @param {string} options.url
   * @param {boolean} [options.autoPlay=true]
   * @param {boolean} [options.muted=true]
   * @param {boolean} [options.loop=true]
   * @param {boolean} [options.noControls=false]
   * @returns {{options, type: string}}
   */
  startContentVideo: (options) => {
    return {
      type: actions.START_CONTENT_VIDEO,
      payload: options,
    };
  },
  endContentVideo: () => {
      return {
          type: actions.END_CONTENT_VIDEO_SEQUENCE,
      };
  }
};

export default actions;
