import InfiniteScroll from "react-infinite-scroll-component";
import React from "react";
import PropTypes from "prop-types";

Pager.propTypes = {
  height: PropTypes.number,
  length: PropTypes.number,
  pageNext: PropTypes.func,
  scrollContainerId: PropTypes.string,
  children: PropTypes.node,
  loader: PropTypes.node,
  hasMore: PropTypes.bool,
  endMessage: PropTypes.node,
  initialScrollY: PropTypes.number,
};

function Pager({height, length, pageNext, scrollContainerId, children, loader, hasMore, endMessage, initialScrollY}) {
  return <InfiniteScroll
    height={height}
    dataLength={length}
    next={pageNext}
    hasMore={hasMore}
    loader={loader}
    endMessage={endMessage}
    scrollableTarget={scrollContainerId}
    initialScrollY={initialScrollY || 0}
  >
    {children}
  </InfiniteScroll>
}

export default Pager;
