import {all, takeLatest, takeEvery, call, put, delay} from "redux-saga/effects";
import {DocumentSnapshot, Query} from "firebase/firestore";
import taskActions from "./actions";
// import {getIdTokenResult} from 'firebase/auth'
import {buildQuery, getDocRef, getDocument, getDocuments, getPath} from "@web/lib/firestore.db";
import {toFirestoreQueryItems, firestore as channel} from "../channels";
import {auth} from "@web/lib/firebase";
import actions from "./actions";

// function* getTrelloOrgIds() {
//   if (!auth.currentUser) return [];
//   const {claims} = yield call(getIdTokenResult, auth.currentUser);
//   let {trelloOrgIds} = claims;
//   if (!trelloOrgIds) {
//     // console.log('refreshing claims to get trelloOrgIds');
//     // const refreshTokenResult = yield call(getIdTokenResult, auth.currentUser, true);
//     // trelloOrgIds = refreshTokenResult.claims.trelloOrgIds;
//   }
//   if (!trelloOrgIds?.length) return [];
//   return [...trelloOrgIds] || []; //, ["600624ff9d4d360a0c8d36ec"]
// }
// const defaultTodosFields = {'==': {status: 'new'}}

/**
 * Get todos query
 * @param {{limit: number}} options
 * @returns {Query}
 */
function getQuery(options = {}) {
  return buildQuery(["user", auth.currentUser.uid, "apps"], {}, {limit: options.limit || 10, order: [['date', 'desc']]});
}

//
// /**
//  * Get todos query
//  * @param {{limit: number}} options
//  * @returns {Query}
//  */
// function getAppServicesQuery(options = {}) {
//   return buildQuery(
//     ["user", auth.currentUser.uid, "app-services"],
//     {"==": {appId: linesContext.appId}},
//     {limit: options.limit || 10, order: [['date', 'desc']]});
// }

function* syncUserApps() {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getQuery({limit: 1}),
    success: actions.SYNC_USER_APPS_SUCCESS,
  }];
  yield call(channel, listenTo);
}

function* syncUserAppService(servicePath) {
  if (!auth.currentUser) return;
  const listenTo = [{
    ref: getDocRef(servicePath),
    success: actions.SYNC_USER_APP_SERVICE_SUCCESS,
  }];
  yield call(channel, listenTo);
}

function* loadUserApps() {
  const query = getQuery();
  const querySnapshot = yield call(getDocuments, query);
  console.log("querySnapshot", querySnapshot.docs);
  yield put({
    type: taskActions.LOAD_USER_APPS_SUCCESS,
    payload: {
      apps: toFirestoreQueryItems(querySnapshot),
    },
  });

  delay(5000);
  yield call(syncUserApps);
}

function* loadUserAppService({servicePath, appId}) {
  const snapshot = yield call(getPath, servicePath);
  yield put({
    type: taskActions.LOAD_USER_APP_SERVICE_SUCCESS,
    payload: {
      [servicePath]: snapshot.data(),
    },
  });

  delay(5000);
  yield call(syncUserAppService, servicePath);
}

export default function* rootSaga() {
  yield all([
    takeLatest(taskActions.LOAD_USER_APPS, loadUserApps),
    takeEvery(taskActions.LOAD_USER_APP_SERVICE, loadUserAppService),
  ]);
}
